import React from 'react';
import Layout from '../components/Layout';
import DocumentsMenu from '../components/DocumentsMenu';
import SecondPageLayout from '../components/Layout/SecondPageLayout';

const ChooseAreaDocsPage = () => {
    return (
        <SecondPageLayout withFooter={true}>
            <DocumentsMenu.ChoosedArea/>
        </SecondPageLayout>
    );
};

export default ChooseAreaDocsPage;