import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { useParams } from 'react-router-dom'
import { useDocuments } from '../store/documents.store'
import { useArea } from '../store/area.store'
import { DocumentService } from '../services/document.service'
import moment from 'moment'

const fetchData = async (
    documentType: string,
    userExtId: string,
    area: string,
    dateFrom: string,
    dateTo: string,
    areas?: string
): Promise<IDocument[]> => {
  return await DocumentService.getDocuments(
        documentType,
        userExtId,
        area,
        dateFrom,
        dateTo,
        areas
    )
}

const useDataDocuments = () => {
  const {user} = useAuth()
  const {documentType} = useParams()
  const {choosedArea} = useArea()
  const {dateFrom,dateTo,choosedAreas} = useDocuments()
  const areaCodes = choosedAreas.map(area => area.code).join(',');
  const { data, error, isLoading, mutate } = useSWR<IDocument[]>(
    `/client/documents/${documentType}/${user?.extId}/${choosedArea?.code}/${dateFrom.toString()}/${dateTo.toString()}?areas=${areaCodes}`,
    () => fetchData(
        documentType!,
        user?.extId!,
        choosedArea?.code!,
        moment(dateFrom).format('YYYY-MM-DD'),
        moment(dateTo).format('YYYY-MM-DD'),
        areaCodes
    )
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataDocuments

