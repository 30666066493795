import React, { useState } from 'react';
import useDataArea from '../../hooks/useDataArea';
import { Box, Typography, Collapse, IconButton } from '@mui/material';
import AreaCrad from './AreaCrad';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const AreaList = () => {
    const { data } = useDataArea();
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', alignItems:'center' }} onClick={handleExpandClick}>
                <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                    הסניפים שלי
                </Typography>
                <IconButton
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    {expanded ? <ExpandLessIcon sx={{color:'#154D72'}}/> : <ExpandMoreIcon sx={{color:'#154D72'}}/>}
                </IconButton>
            </Box>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {data?.map((item, index) => (
                    <AreaCrad key={index} item={item} />
                ))}
            </Collapse>
        </Box>
    );
};

export default AreaList;
