import { ApiInterceptor } from "./api.interceptor";

export const SliderService = {
    async getSliders(): Promise<ISlider[]>{
        const response = await ApiInterceptor<ISlider[]>(`/client/slider`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

}