import { Box, Typography } from '@mui/material';
import React from 'react';
import Permissions from '../components/Permissions';
import Layout from '../components/Layout';

const PermissionPage = () => {
    return (
        <Layout>
            <Box sx={{display:'flex', gap:'5px', padding:'0 10px'}}>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Component 2">
                    <path
                        id="Icon"
                        d="M8 8.75879H8.01M2 5.95879L2 10.4333C2 10.9225 2 11.1671 2.05526 11.3973C2.10425 11.6013 2.18506 11.7964 2.29472 11.9754C2.4184 12.1772 2.59135 12.3501 2.93726 12.696L10.6059 20.3647C11.7939 21.5527 12.388 22.1467 13.0729 22.3693C13.6755 22.5651 14.3245 22.5651 14.927 22.3693C15.612 22.1467 16.2061 21.5527 17.3941 20.3647L19.6059 18.1529C20.7939 16.9648 21.388 16.3708 21.6105 15.6858C21.8063 15.0833 21.8063 14.4343 21.6105 13.8317C21.388 13.1468 20.7939 12.5527 19.6059 11.3647L11.9373 3.69605C11.5914 3.35014 11.4184 3.17719 11.2166 3.05351C11.0376 2.94385 10.8425 2.86304 10.6385 2.81405C10.4083 2.75879 10.1637 2.75879 9.67452 2.75879L5.2 2.75879C4.0799 2.75879 3.51984 2.75879 3.09202 2.97678C2.7157 3.16852 2.40973 3.47448 2.21799 3.85081C2 4.27863 2 4.83868 2 5.95879ZM8.5 8.75879C8.5 9.03493 8.27614 9.25879 8 9.25879C7.72386 9.25879 7.5 9.03493 7.5 8.75879C7.5 8.48265 7.72386 8.25879 8 8.25879C8.27614 8.25879 8.5 8.48265 8.5 8.75879Z"
                        stroke="#2F4B6C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    </g>
                </svg>
                <Typography color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                    הוספת תפקיד חדש 
                </Typography>
            </Box>
            <Box sx={{mt:'30px'}}>
                <Permissions.Tags/>
            </Box>
            <Box sx={{mt:'30px'}}>
                <Permissions.List/>
            </Box>
        </Layout>
    );
};

export default PermissionPage;