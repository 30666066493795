import React from 'react';
import { Box, Typography } from '@mui/material';
import useDataCatalog from '../../hooks/useDataCatalog';
import CatalogCard from './CatalogCard';
import { useCatalog } from '../../store/catalog.store';
import { themeColors } from '../../styles';
import CatalogCardSkeleton from './CatalogCardSkeleton';

const CatalogList = () => {
    const { data, isLoading } = useDataCatalog();
    const { search, selectedCategories, openFavorite, favorites } = useCatalog();

    if (isLoading) {
        return (
            <Box>
                {[...Array(5)].map((_, index) => (
                    <CatalogCardSkeleton key={index} />
                ))}
            </Box>
        );
    }

    return (
        <Box>
            {data?.map((item, key) => {
                const isCategorySelected = selectedCategories.length === 0 || selectedCategories.includes(item.category);
                
                const filteredProducts = item.products?.filter((prod) => 
                    prod.sku.toLowerCase().includes(search.toLowerCase()) || 
                    prod.title.toLowerCase().includes(search.toLowerCase())
                );

                const shouldShowCategory = isCategorySelected && 
                    (filteredProducts.length > 0 || selectedCategories.length === 0);

                const productsToDisplay = openFavorite ? 
                    favorites.filter(fav => filteredProducts.some(prod => prod.id === fav.id)) :
                    selectedCategories.length === 0 ? item.products : filteredProducts;

                return shouldShowCategory ? (
                    <Box key={key} sx={{margin:'20px 0'}}>
                        <Typography fontWeight={700} fontSize={'15px'} lineHeight={'21px'} color={themeColors.title} sx={{padding:'0 10px'}}>
                            {item.category}
                        </Typography>
                        {productsToDisplay.map((prod) => (
                            <CatalogCard key={prod.id} item={prod} />
                        ))}
                    </Box>
                ) : null; 
            })}
        </Box>
    );
};

export default CatalogList;
