import React from 'react';
import useDataMachineDocument from '../../hooks/useDataMachineDocument';
import { Box, Chip, Divider, ImageList, ImageListItem, Paper, Rating, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import useDataQuestion from '../../hooks/useDataQuestion';
import { QuestionEnum } from '../../types/questionEnum';
import Grid from '@mui/material/Grid2';

const FilesList = () => {
    const {data} = useDataMachineDocument()
    const [value, setValue] = React.useState<number | null>(2);
    const [selectedChips, setSelectedChips] = React.useState<string[]>([]); 
    const {data: questionData} = useDataQuestion(QuestionEnum.SERVICE)

    const handleChipClick = (chipValue: string) => {
        setSelectedChips((prevSelected) =>
            prevSelected.includes(chipValue)
                ? prevSelected.filter((item) => item !== chipValue) 
                : [...prevSelected, chipValue] 
        );
    };

    const splitedTag = () => {
        const regex = /\(([^)]+)\)/;
        const match = data?.description.match(regex);
        
        if (match && match[1]) {
            // Split the matched content by '|'
            return match[1].split('|').map(tag => tag.trim());
        }
    
        return [];
    }

    
    const handleDelete = (chipValue: string) => {
        setSelectedChips((prevSelected) =>
            prevSelected.filter((item) => item !== chipValue)
        );
    };
    return (
        <Box sx={{padding:'10px'}}>
            <Paper sx={{ bgcolor: '#27C9D4', textAlign: 'center', alignItems: 'center', padding: 2, borderRadius:'15px' }}>
                <Typography color="white" fontWeight={700} fontSize={15}>
                    דרגו את החוויה שלכם
                </Typography>
                <Rating
                    size="large"
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                />
                <Typography color="white">
                    אנא עזור לנו להשתפר,<br />
                    נשמח לשמוע ממה הייתם פחות מרוצים:
                </Typography>
                {questionData?.map((chip, index) => (
                    <Chip
                        key={index}
                        label={chip.title}
                        onClick={() => handleChipClick(chip.title)}
                        variant={selectedChips.includes(chip.title) ? "filled" : "outlined"}
                        color={selectedChips.includes(chip.title) ? "primary" : "default"}
                    />
                ))}
                <TextField
                    rows={3}
                    multiline
                    fullWidth
                    variant='outlined'
                    sx={{
                        bgcolor:'white',
                        borderRadius:'14px'
                    }}
                />
            </Paper>
            <Box sx={{display:'flex', justifyContent:'center'}}>
                <svg width="117" height="18" viewBox="0 0 117 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.5479 -45.5996H98.4396C108.638 -45.5996 116.894 -37.3495 116.894 -27.1874V-18.7495C116.894 -8.57399 108.625 -0.337288 98.4396 -0.337288H66.7808V15.9105C66.7808 17.3667 64.9937 18.0659 64.0056 16.9963L48.005 -0.323917H18.5479C8.36263 -0.323917 0.09375 -8.57399 0.09375 -18.7495V-27.1874C0.09375 -37.3495 8.36263 -45.5996 18.5479 -45.5996Z"
                        fill="#27C9D4"
                        style={{ fill: 'rgb(39, 201, 212)' }}
                    />
                </svg>
            </Box>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <img src={`${process.env.REACT_APP_MEDIA}/ilustrate3.png`} />
            </Box>
            <Divider sx={{width:'100%'}}/>
            <Typography textAlign={'center'} color='#154D72' fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                {data?.machine}
            </Typography>
         
            <Typography textAlign={'center'} color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>
            מס׳ מכשיר {data?.serialNumber} · סניף {data?.areaDescription} · {moment(data?.createdAt).format('DD/MM/YYYY')}
            </Typography>
            <Typography fontWeight={700} fontSize={20} color='#154D72' lineHeight={'28px'}>
            פרטי התקלה
            </Typography>
            <Grid container spacing={1} sx={{margin:'10px 0'}}>
                {splitedTag()?.map((item,index) => 
                <Grid key={index}>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            label={item}
                            variant={'filled'} 
                            color='primary'
                            sx={{
                                fontSize:'12px',
                                fontWeight:700,
                                lineHeight:'19px',
                                '&.MuiChip-filled': {
                                    backgroundColor: 'primary.main',
                                    color: 'white', 
                                },
                            }}
                        />
                    </Stack>
                </Grid>
                )}
            </Grid>
            <Typography color='#436F7C' fontSize={'13px'} fontWeight={400} variant="body1" component="div" dangerouslySetInnerHTML={{ __html: data?.description! }} />
            <Typography color='#154D72' fontSize={'15px'} lineHeight={'22.5px'} fontWeight={700} sx={{marginTop:'10px'}}>
                קבצים מצוריפים
            </Typography>
            <ImageList cols={3} rowHeight={164} gap={10}>
            {(data?.files || []).map((item, index) => (
                <ImageListItem key={index}>
                <img
                    src={item.base64} 
                    alt={item.fileName}
                    width={70}
                    height={70}
                    style={{ borderRadius: '15px' }}
                />
                </ImageListItem>
            ))}
            </ImageList>
                
        </Box>
    );
};

export default FilesList;