import { ApiInterceptor } from "./api.interceptor";

export const AvatarService = {
    async getAvatars(userId: string | number){
        const response = await ApiInterceptor<Hydra<IAvatar[]>>(`/api/avatarsUser/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response["hydra:member"]
    },  

    async saveAvatar(apiKey: string | number ,userId:number){
        const response = await ApiInterceptor<SaveAvatar>(`/client/saveAvatar`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({apiKey,userId})
        });
        return response
    }
}