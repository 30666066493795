import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { AreaService } from '../services/area.service'
import { ServicesService } from '../services/services.service'
import { useParams } from 'react-router-dom'

const fetchData = async (
  userExId: string,
  sku: string
): Promise<IProduct> => {
  return await ServicesService.getMachine(userExId,sku)
}

const useDataMachine = () => {
  const { user } = useAuth()
  const {sku} = useParams()

  const { data, error, isLoading, mutate } = useSWR<IProduct>(
    `/client/machine/${user?.extId}/${sku}`,
    () => fetchData(user?.extId!,sku!)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataMachine

