import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterApp from './RouterApp';
import { AuthProvider } from './provider/AuthProvider';
import {LanguageAndStyleProvider} from './provider/LanguageAndStyleProvider';
import { ModalsProvider } from './provider/ModalProvider';
import { DraggablesProvider } from './provider/DraggableProvider';
import { NotifyProvider } from './provider/NotifyProvider';
import { SplashsProvider } from './provider/SplashProvider';

function App() {
  return (
    <BrowserRouter>
      <LanguageAndStyleProvider>
        {/* <SplashsProvider> */}
          <AuthProvider>
            <ModalsProvider>
              <NotifyProvider>
                <DraggablesProvider>
                  <RouterApp />
                </DraggablesProvider>
              </NotifyProvider>
            </ModalsProvider>
          </AuthProvider>
        {/* </SplashsProvider> */}
      </LanguageAndStyleProvider>
    </BrowserRouter>
  );
}

export default App;
