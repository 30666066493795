import { Avatar, Box, Typography } from '@mui/material';
import React, { FC } from 'react';

interface CardProps {
    item: INotification
}

const Card: FC<CardProps> = ({ item }) => {
    return (
        <Box sx={{bgcolor:'#E6F2F9', padding:'20px 0'}}>
            <Box sx={{ display: 'flex', gap: '15px', height: '100%', alignItems: 'center' }}>
                <Box sx={{ width: '20px', display: 'flex', justifyContent: 'center', height: '100%' }}>
                    <Box sx={{ bgcolor: '#FF32BB', width: '6px', height: '6px', borderRadius: '50%' }} />
                </Box>
                <Box>
                    <Avatar src={`${process.env.REACT_APP_MEDIA}/boxplaceholder.png`} sx={{ width: '80px', height: '80px' }} />
                </Box>
                <Box>
                    <Typography variant="h6" align="center">
                        {item?.title}
                    </Typography>
                    <Typography variant="body2" align="center">
                        {item?.description}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Card;
