import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Link,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import OTPInput from '../../utils/OtpInput';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';

const AuthStepTwoForm = () => {
    const [error, setError] = useState('');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const { validationStepTwo } = useAuth();
    const { handleSubmit, formState: { errors } } = useForm<IResetPasswordStepOne>();

    const onSubmit = async (data: IResetPasswordStepOne) => {
        setError('');
        try {
            const response = await validationStepTwo(otp);
            if (response.status === 'success') {
                navigate(URLS.AuthStepThree.LINK);
            } else {
                setError(response.message);
            }
        } catch (e) {
            setError('שגיאה');
        }
    };
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title} textAlign={'center'}>
                    קוד סודי
                </Typography>
                <Typography variant="body1" color='#436F7C' textAlign={'center'}>
                    שלחנו לכם קוד בהודעת SMS,<br/>
                    כתבו אותו כאן והמתינו לאישור.
                </Typography>
                <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', margin: '30px 0' }}>
                    <OTPInput otp={otp} setOtp={setOtp} />
                </Box>
                <Typography variant="body1" color='#436F7C' textAlign={'center'}>
                    לא קיבלתם סמס?
                    <Link underline="always" sx={{ display: 'inline', padding:'0 5px', cursor:'pointer' }}>
                        <Typography variant="body1" component="span" color={themeColors.primary}>
                        נסו לשלוח שוב
                        </Typography>
                    </Link>
                </Typography>
                {error && (
                <Typography color="error" sx={{ textAlign: 'center', margin:"20px 0" }}>
                    {error}
                </Typography>
                )}
            </Box>
            <Box sx={{ bottom: 0, width: '100%' }}>
                <Button variant='contained' fullWidth type='submit' disabled={otp.length !== 6}>
                    המשך
                </Button>
            </Box>
        </form>
    );
};

export default AuthStepTwoForm;
