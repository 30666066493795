import { createTheme } from '@mui/material';

// Color definitions based on Figma design
export const themeColors = {
  whiteBaseCards: '#FFFFFF', // White - Base, Cards
  baseDark: '#F5F7FA', // Base - Dark
  primary: '#27C9D4', // Main Interactive - Links
  attention: '#FF32BB', // Attention
  attentionTextStroke: '#E92081', // Attention Text+H... Stroke
  title: '#2F4B6C', // Title
  deselectedMenuItems: '#546D79', // Deselected Menu Items
  paragraphInputTag: '#546D79', // Paragraph+Input te... Tag
  placeholdersInput: '#8EA6B2', // Placeholders+Stroke... Input
  popupOverlay: '#114660', // Popup Overlay
  newNotificationBase: '#E6F2F9', // New Notification Base
  accessibility: '#003F89', // Accessibility
  positiveReviewTag: '#FFE6AF', // Positive Review Tag
  positiveReviewSelected: '#E29C00', // Positive Review... Selected
  positiveToggle: '#1E536E', // Positive Toggle
  negativeToggle: '#FF9DBA', // Negative Toggle
  ownerTag: '#950025', // Owner Tag
  adminTag: '#0050A5', // Admin Tag
  vpTag: '#269E36', // VP Tag
  branchMgrTag: '#2169B6', // Branch Mngr Tag
  accountantTag: '#1E676E', // Accountant Tag
  tagColor1: '#AA0003', // Tag Color 1
  tagColor2: '#C2205B', // Tag Color 2
  tagColor4: '#6C06AC', // Tag Color 4
  tagColor5: '#E4B023', // Tag Color 5
  error: '#C52A43', // Error color
  warning: '#F3C521', // Warning color
  info: '#FF32BB', // Info color
  success: '#30AC5A', // Success color
};

// Typography definitions based on Figma design
const typography = {
  fontFamily: "Noto Sans Hebrew, Arial",
  h1: {
    fontSize: '25px',
    fontWeight: 800,
  },
  onboardingParagraph: {
    fontSize: '20px',
    fontWeight: 500,
  },
  onboardingWizardActiveStep: {
    fontSize: '12px',
    fontWeight: 700,
  },
  header3: {
    fontSize: '15px',
    fontWeight: 700,
  },
  header2: {
    fontSize: '20px',
    fontWeight: 700,
  },
  userInput: {
    fontSize: '13px',
    fontWeight: 700,
  },
  placeholder: {
    fontSize: '13px',
    fontWeight: 700,
  },
  link: {
    fontSize: '13px',
    fontWeight: 400,
  },
  errorMessage: {
    fontSize: '13px',
    fontWeight: 400,
  },
  mainButton: {
    fontSize: '15px',
    fontWeight: 700,
  },
  adCardTitle: {
    fontSize: '20px',
    fontWeight: 700,
  },
  errorButton: {
    fontSize: '15px',
    fontWeight: 700,
  },
  reviewTagNegative: {
    fontSize: '12px',
    fontWeight: 400,
  },
  reviewTagPositive: {
    fontSize: '12px',
    fontWeight: 400,
  },
  itemCounter: {
    fontSize: '20px',
    fontWeight: 700,
  },
  itemCounterInProgress: {
    fontSize: '15px',
    fontWeight: 700,
  },
  tag: {
    fontSize: '12px',
    fontWeight: 700,
  },
  selectedTag: {
    fontSize: '12px',
    fontWeight: 700,
  },
  accessibilityParagraph: {
    fontSize: '15px',
    fontWeight: 400,
  },
  notFoundPageHeader: {
    fontSize: '13px',
    fontWeight: 700,
  },
  notFoundPageParagraph: {
    fontSize: '13px',
    fontWeight: 400,
  },
};

// Complete theme setup
const theme = createTheme({
  direction: "rtl",
  typography: typography,
  palette: {
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.baseDark,
    },
    error: {
      main: themeColors.error,
    },
    warning: {
      main: themeColors.warning,
    },
    info: {
      main: themeColors.info,
    },
    success: {
      main: themeColors.success,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor:'#FFF',
            borderRadius: '14px', // Adjust to match the rounded corners in your design
            '& fieldset': {
              border: '1px solid #80A8B6', // Adjust the color to match your design
            },
            '&:hover fieldset': {
              // borderColor: '#90A4AE', // Slightly darker on hover if needed
            },
            '&.Mui-focused fieldset': {
              // borderColor: '#607D8B', // Adjust for focused state
            },
            '& input': {
              // padding: 0,
              // height: '100%',
            },
            '& input::placeholder': {
              textAlign: 'left',
            },
          },
        }
      }
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(17, 70, 96, 0.4)', 
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 11px 24px 0px rgba(0, 51, 31, 0.10)', 
        },
      },
    },


    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: themeColors.baseDark,
          margin: 0, // Remove default margin
          padding: 0, // Remove default padding
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          height: '52px',
          fontSize: '15px',
          fontWeight: 700,
          '&.Mui-disabled': {
            background: 'var(--placeholders-stroke-input, #80A8B6)',
            boxShadow: '0px 3px 10px 0px rgba(0, 125, 131, 0.30), 0px 3px 10px 0px color(display-p3 0.0928 0.4813 0.5072 / 0.30)',
            color: '#ffffff',
          },
        },
        contained: {
          color:'white',
          // backgroundColor: 'var(--placeholders-stroke-input, #80A8B6)', // Customize contained button background color
          // '&:hover': {
          //   backgroundColor: 'var(--placeholders-stroke-input-hover, #5F8A9E)', // Hover color for contained button
          // },
          // '&.Mui-disabled': {
          //   background: 'var(--placeholders-stroke-input, #80A8B6)',
          //   color: '#ffffff',
          // },
        },
        outlined: {
     
       
        },
      }
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '13px',
          backgroundColor: 'var(--white-base-cards, #FFF)',
          boxShadow: `0px 11px 24px 0px rgba(0, 51, 31, 0.10), 
                      0px 11px 24px 0px color(display-p3 0.0561 0.1964 0.129 / 0.10)`,
        }
      }
    },

    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "orange",
          display: 'block'
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: "red",
            display: 'block'
          }
        },
        track: {
          backgroundColor: "white",
          ".Mui-checked + &": {
            backgroundColor: "white"
          }
        }
      }
    },


  },
});

// Exporting the theme
export default theme;
