import { Chip } from '@mui/material';
import React, { FC, useState } from 'react';
import Stack from '@mui/material/Stack';

interface IChipSelector {
    item: IPermission;
    handleClick: (item: IPermission) => void;
    isSelected: boolean
}

const ChipSelector: FC<IChipSelector> = ({ item, handleClick, isSelected }) => {
    
    const handleChipClick = () => {
        handleClick(item); 
    };

    return (
        <Stack direction="row" spacing={1}>
            <Chip
                label={item?.title}
                onClick={handleChipClick}
                variant={isSelected ? 'filled' : 'outlined'} 
                style={{ backgroundColor: isSelected ? item.color : undefined, color: isSelected ? 'white' : item.color }} 
            />
        </Stack>
    );
};

export default ChipSelector;
