import { Box } from '@mui/material';
import React from 'react';
import Auth from '../components/Auth';

const AreaChoosedPage = () => {
    return (
        <Box>
            <Auth.AuthHeader/>
            <Auth.AreaChoosed/>
        </Box>
    );
};

export default AreaChoosedPage;