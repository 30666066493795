import React from 'react';
import useDataFaq from '../../hooks/useDataFaq';
import { Divider, List as MuiList } from '@mui/material';
import Card from './Card';
import { useFaq } from '../../store/faq.store';

const List = () => {
    const { data } = useDataFaq();
    const { title } = useFaq();

    const filteredData = data?.filter(item => 
        item.title.toLowerCase().includes(title.toLowerCase()) || 
        item.description.toLowerCase().includes(title.toLowerCase())
    );

    return (
            <MuiList>
                {filteredData?.map((item, index) => (
                    <React.Fragment key={index}>
                        <Card item={item} />
                        {index < filteredData.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </MuiList>
    );
};

export default List;
