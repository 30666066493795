import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDataLastServices from '../../hooks/useDataLastServices'; // Assuming you have a similar hook
import URLS from '../../enums/route';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Box, Paper, Typography } from '@mui/material';
import { ServiceStatus } from '../../enums/serviceStatus';

const ServicesDraggable: React.FC = () => {
    const [openServices, setOpenServices] = useState<boolean>(false);
    const { pathname } = useLocation();
    const { data: services } = useDataLastServices(true); 
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const handleStart = () => {
        setIsDragging(false); 
    };

    const handleDrag = () => {
        setIsDragging(true); 
    };

    const handleStop = (e: DraggableEvent, data: DraggableData, item?: { documentNumber: string }) => {
        if (!isDragging) {
            if (item) {
                navigate(`${URLS.SERVICE_DOCUMENT_PAGE.LINK}/${item.documentNumber}`);
            } else {
                setOpenServices(!openServices); 
            }
        }
        setIsDragging(false);
    };

    return (
        <>
            {(pathname.includes(URLS.SERVICE.LINK) || pathname.includes(URLS.MACHINE_PAGE.LINK)) &&
                <>
                    {services && services.length > 0 &&
                        <>
                            {!openServices && services.length > 8 ?
                                <Draggable onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '70px',
                                            height: '70px',
                                            borderRadius: '50%',
                                            background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                            color: 'white',
                                            position: 'relative',
                                            boxShadow: 3,
                                            border: '2px solid white'
                                        }}
                                        onClick={() => setOpenServices(!openServices)}
                                    >
                                        <img src={`${process.env.REACT_APP_MEDIA}/repair.svg`} alt="icon" width={'35px'} height={'35px'} />
                                        <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                            {`${services.length}`}
                                        </Typography>
                                        <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                            {'קריאות פתוחות'}
                                        </Typography>
                                    </Paper>
                                </Draggable>
                                :
                                (openServices || services.length < 8) && services.map((item) => 
                                    <Draggable key={item.documentNumber} onStart={handleStart} onDrag={handleDrag} onStop={(e, data) => handleStop(e, data, item)}>
                                        <Paper
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '70px',
                                                height: '70px',
                                                borderRadius: '50%',
                                                background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                                color: 'white',
                                                position: 'relative',
                                                boxShadow: 3,
                                                border: '2px solid white'
                                            }}
                                            onClick={() => navigate(`${URLS.SERVICE_DOCUMENT_PAGE.LINK}/${item.documentNumber}`)}
                                        >
                                            <img src={`${process.env.REACT_APP_MEDIA}/${item.status === ServiceStatus.Final ? 'completeRepair' : 'repair'}.svg`} alt="icon" width={'35px'} height={'35px'} />
                                            <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                                {`קריאה`}
                                            </Typography>
                                            <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                                {item.documentNumber.slice(-4)}
                                            </Typography>
                                        </Paper>
                                    </Draggable>
                                )
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default ServicesDraggable;
