import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomStepper from '../Common/CustomStepper';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { themeColors } from '../../styles';
import URLS from '../../enums/route';
const AuthHeader = () => {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const steps = [
        { label: 'אימות' },
        { label: 'קוד' },
        { label: 'פרטים' },
      ];

    const handleStep = () => {
        if(pathname === URLS.AuthStepOne.LINK){
            return 0
        } else if(pathname === URLS.AuthStepTwo.LINK) {
            return 1
        } else if(pathname === URLS.AuthStepThree.LINK) {
            return 2
        } else {
            return 3
        }
    }
    const activetedStepper = pathname === URLS.AuthStepOne.LINK || pathname === URLS.AuthStepTwo.LINK || pathname === URLS.AuthStepThree.LINK
    const [activeStep, setActiveStep] = React.useState<number>(handleStep());
    return (
        <Box>
            <Box sx={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center', position:'relative'}}>
                <IconButton sx={{position:'absolute', left:'0px', top:'-10px'}} onClick={() => navigate(-1)}> 
                    <ArrowForwardIosRoundedIcon color='primary'/>
                </IconButton>
                {   activetedStepper &&
                    <CustomStepper steps={steps} activeStep={activeStep} iconSize={20}/>
                }
            </Box>
            <Box sx={{paddingTop:'30px'}}>
                <Typography sx={{color:themeColors.title, fontWeight:800, fontSize:'25px', lineHeight:'35px', textAlign:'center'}}>
                ברוכים הבאים
                </Typography>
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src={`${process.env.REACT_APP_MEDIA}/logo.png`} />
                </Box>
            </Box>
        </Box>
    );
};

export default AuthHeader;