import { ApiInterceptor } from "./api.interceptor";

export const PermissionService = { 
    async getPermissions(userId: string | number){
        const response = await ApiInterceptor<IPermission[]>(`/client/permissions/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async updatePermission(id:number, field: string, value: boolean){
        const response = await ApiInterceptor<ApiResponse>(`/api/permission_detaileds/${id}`, {
            method: 'PATCH',
            body: JSON.stringify({[field]:value})
        });
        return response
    },

    async createPermission(user: IUser, text: string, color:string){
        const response = await ApiInterceptor<ApiResponse>(`/client/permissions`, {
            method: 'POST',
            body: JSON.stringify({user, text,color})
        });
        return response
    },

    async getCompanyUsers(userExtId:string){
        const response = await ApiInterceptor<IUser[]>(`/client/employer/${userExtId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

    async createUser(fullName: string, phone: string, areas: string[],userExtId:string){
        const response = await ApiInterceptor<ApiResponse>(`/client/createUser/${userExtId}`, {
            method: 'POST',
            body: JSON.stringify({fullName,phone,areas})
        });
        return response
    },

    async updateUser(fullName: string, areas: string[],userId:string|number, permission: IPermission | null){
        const response = await ApiInterceptor<ApiResponse>(`/client/updateUser/${userId}`, {
            method: 'POST',
            body: JSON.stringify({fullName,areas,permission})
        });
        return response
    },

    async unpublishUser(userId:string|number){
        const response = await ApiInterceptor<ApiResponse>(`/client/unpublishUser/${userId}`, {
            method: 'PATCH',
        });
        return response
    },

    async createFreeField(keyword: string, valueword: string, userId: string | number){
        const response = await ApiInterceptor<ApiResponse>(`/client/createFreeField/${userId}`, {
            method: 'POST',
            body: JSON.stringify({keyword,valueword})
        });
        return response
    }
}