import React from 'react';
import Layout from '../components/Layout';
import Machine from '../components/Machine';
import SecondPageLayout from '../components/Layout/SecondPageLayout';

const MachinePage = () => {
    return (
        <SecondPageLayout>
            <Machine.Info/>
            <Machine.Tags/>
            <Machine.FileUpload/>
        </SecondPageLayout>
    );
};

export default MachinePage;