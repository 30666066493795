import { ApiInterceptor } from "./api.interceptor";

export const AuthService = {
    async login(obj: ILogin): Promise<IUserResponse>{
        const response = await ApiInterceptor<IUserResponse>(`/api/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        return response
    },

    async validationStepOne(obj: IAuthStepOne): Promise<IAuthStepOneAndTwoResponse>{
        const response = await ApiInterceptor<IAuthStepOneAndTwoResponse>(`/auth/validationStepOne`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        return response
    },
    async validationStepTwo(obj: IAuthStepTwo): Promise<IAuthStepOneAndTwoResponse>{
        const response = await ApiInterceptor<IAuthStepOneAndTwoResponse>(`/auth/validationStepTwo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        return response
    },
    async validationStepThree(obj: IAuthStepThree): Promise<IAuthStepThreeResponse>{
        const response = await ApiInterceptor<IAuthStepThreeResponse>(`/auth/validationStepThree`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        return response
    },
    async restorePasswordStepOne(obj: IResetPasswordStepOne): Promise<ApiResponse>{
        const response = await ApiInterceptor<ApiResponse>(`/auth/restorePasswordStepOne`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        return response
    },
    async restorePasswordStepTwo(obj: IResetPasswordStepTwo): Promise<ApiResponse>{
        const response = await ApiInterceptor<ApiResponse>(`/auth/restorePasswordStepTwo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        return response
    },
    async restorePasswordStepThree(obj: IResetPasswordStepThree): Promise<ApiResponse>{
        const response = await ApiInterceptor<ApiResponse>(`/auth/restorePasswordStepThree`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        });
        return response
    },
}
