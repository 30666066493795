import { Box, Button, Container, Link, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import URLS from '../enums/route';
import { themeColors } from '../styles';
import backgroundGif from '../styles/bg.gif';
import AuthLayout from '../components/Layout/AuthLayout';
const HomeAuth = () => {
    const navigate = useNavigate();
    return (
        <AuthLayout>
            <>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flexGrow: 1, 
                    justifyContent: 'center' 
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={`${process.env.REACT_APP_MEDIA}/authLogo.png`} alt="Auth Logo" />
                        </Box>
                        <Box sx={{ textAlign: 'center', color: '#60696F' }}>
                            <Typography color='#436F7C' fontWeight={500} fontSize={'13px'}>
                                ברוכים הבאים למערכת קסיופאיה,
                            </Typography>
                            <Typography color='#436F7C' fontWeight={500} fontSize={'13px'}>
                                היישומון לכל ההזמנות וקריאות השירות שלכם.
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ mt: '20px', width: '100%' }}>
                    <Box>
                        <Typography color='#436F7C' fontWeight={500} fontSize={'13px'} textAlign={'center'}>
                            ההרשמה לאפליקציה היא ללקוחות בלבד.
                        </Typography>
                        <Typography variant="body1" color='#436F7C' fontWeight={500} fontSize={'13px'} textAlign={'center'}>
                            לא קיבלתם הזמנה במייל?
                            <Link href="#" underline="always" sx={{ display: 'inline', padding:'0 5px' }}>
                                <Typography variant="body1" component="span" color={themeColors.primary}>
                                    צרו קשר
                                </Typography>
                            </Link>
                        </Typography>
                    </Box>
                    <Button 
                        variant="contained" 
                        fullWidth
                        sx={{margin:"20px 0"}}
                        onClick={() => navigate(URLS.AuthStepOne.LINK)}
                    >
                        הרשמה למערכת   
                    </Button>
                    <Button 
                        fullWidth
                        onClick={() => navigate(URLS.AuthLogin.LINK)}
                    >
                        כניסה לחשבון קיים
                    </Button>
                </Box>
            </>
        </AuthLayout>
    
  
    );
};

export default HomeAuth;
