import { Avatar, Box, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { useAvatarStore } from '../../store/avatar.store';

interface CardAvatarProps {
    item: IAvatar
}

const CardAvatar:FC<CardAvatarProps> = ({item}) => {
    const { setChoosedAvatar, setAvatarId } = useAvatarStore()

    const handleClick = () => {
        setAvatarId(item.id)
        setChoosedAvatar(`${process.env.REACT_APP_MEDIA_BACKEND}${item.image.contentUrl}`)
    }
    
    return (
        <IconButton sx={{cursor:'pointer'}} onClick={() => handleClick()}>
            <Avatar sx={{width:'60px', height:'60px'}} alt="Remy Sharp" src={`${process.env.REACT_APP_MEDIA_BACKEND}${item.image.contentUrl}`} />
        </IconButton>
    );
};

export default CardAvatar;