import { ApiInterceptor } from "./api.interceptor";

export const NotificationService = {
    async getNotification(userId: string | number): Promise<INotification[]>{
        const response = await ApiInterceptor<INotification[]>(`/client/notification/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

}