import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface FaqState {
    title: string ,
    setTitle: (title: string) => void
}

export const useFaq = create(
  persist(
    (set, get) => ({
        title: '',
        setTitle: (title) => set({title}),
    }),
    {
      name: 'faq-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<FaqState, FaqState>
  )
)
