import { Box, CardMedia, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';

interface CardProps {
    item: IDocumentItem
}

const Card:FC<CardProps> = ({item}) => {
    return (
        <Box sx={{display:'flex', gap:'10px', justifyContent:'space-between', alignItems:'center', padding:'15px'}}>
            <Box sx={{display:'flex', gap:'10px'}}>
                <CardMedia
                    component="img"
                    image={`${process.env.REACT_APP_MEDIA}/placeholder.png`}
                    alt="Paella dish"
                    sx={{width:'100px', height:'100px', borderRadius:'15px'}}
                />
                <Box>
                    <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                        {item?.title}
                    </Typography>
                    <Typography color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>
                        {item?.product?.packQuantity}
                    </Typography>
                </Box>
            </Box>
            <Typography color='#154D72' fontSize={'20px'} fontWeight={700} letterSpacing={'0.3px'} lineHeight={'18px'}>
            {item?.quantity}
            </Typography>
        </Box>
    );
};

export default Card;