import React from 'react';
import Layout from '../components/Layout';
import { Box } from '@mui/material';
import Service from '../components/Service';

const ServicePage = () => {
    return (
        <Layout>
            <Box>
                <Service.Filter/>
                <Service.LastOrders/>
                <Service.List/>
            </Box>
        </Layout>
    );
};

export default ServicePage;