import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Box, Paper, Typography } from '@mui/material';
import URLS from '../../enums/route';
import { useCart } from '../../store/cart.store';
import { useDocuments } from '../../store/documents.store';

const EditOrderDraggable: React.FC = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { cart } = useCart();
    const { choosedDocument } = useDocuments();
    const [isDragging, setIsDragging] = React.useState<boolean>(false); 

    const handleStart = () => {
        setIsDragging(false); 
    };

    const handleDrag = () => {
        setIsDragging(true); 
    };

    const handleStop = (e: DraggableEvent, data: DraggableData) => {
        if (!isDragging) {
            navigate(choosedDocument ? `${URLS.DOCUMENTS_ITEM.LINK}/${choosedDocument.documentNumber}` : `${URLS.CART.LINK}`);
        }
        setIsDragging(false); 
    };

    return (
        <>
            {(pathname.includes(URLS.CATALOG.LINK) || pathname.includes(URLS.PRODUCT_PAGE.LINK)) &&
                <>
                    {cart?.length > 0 &&
                        <Draggable onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
                            <Paper
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '70px',
                                    height: '70px',
                                    borderRadius: '50%',
                                    background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                    color: 'white',
                                    position: 'relative',
                                    boxShadow: 3,
                                    border: '2px solid white'
                                }}
                                onClick={() => navigate(choosedDocument ? `${URLS.DOCUMENTS_ITEM.LINK}/${choosedDocument.documentNumber}` :`${URLS.CART.LINK}`)}
                            >
                                <img src={`${process.env.REACT_APP_MEDIA}/cart.svg`} alt="icon" width={'35px'} height={'35px'} />
                                <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                    {choosedDocument ?
                                        <>
                                            עדכון הזמנה {choosedDocument.documentNumber}
                                        </>
                                        :
                                        <>
                                            הזמנה 
                                        </>
                                    }
                                </Typography>
                                <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                    {choosedDocument ?
                                        <>
                                            {choosedDocument.documentNumber}
                                        </>
                                        :
                                        <>
                                                
                                        </>
                                    }
                                </Typography>
                            </Paper>
                        </Draggable>
                    }
                </>
            }
        </>
    );
};

export default EditOrderDraggable;
