import Filter from "./Filter";
import LastOrders from "./LastOrders";
import CatalogList from "./CatalogList";
import CatalogItem from "./CatalogItem";
import Options from "./Options";
export default {
    Filter,
    LastOrders,
    CatalogList,
    CatalogItem,
    Options
}