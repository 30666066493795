import { Box, Skeleton } from '@mui/material';
import React from 'react';

const CardNotification = () => {
    return (
        <Box sx={{bgcolor:'#E6F2F9', padding:'20px 35px', display:'flex', gap:'20px'}}>
                <Skeleton variant="circular" sx={{ width: '80px', height:'80px' }} />
                <Skeleton variant="text" sx={{ width: '60%', height:'44px' }} />
        </Box>
    );
};

export default CardNotification;