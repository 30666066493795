import React from 'react';
import { useFaq } from '../../store/faq.store';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Filter = () => {
    const { title, setTitle } = useFaq();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '10px' }}>
            <TextField
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                placeholder='שאלו אותנו כל דבר'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default Filter;
