import React from 'react';
import { Box } from '@mui/material';
import Catalog from '../components/Catalog';
import SecondPageLayout from '../components/Layout/SecondPageLayout';

const ProductPage = () => {
    return (
        <SecondPageLayout addToCartFooter={true}>
            <Box>
                <Catalog.CatalogItem/>
            </Box>
        </SecondPageLayout>
    );
};

export default ProductPage;