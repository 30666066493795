import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDataLastOrders from '../../hooks/useDataLastOrders';
import URLS from '../../enums/route';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Paper, Typography } from '@mui/material';
import { OrderStatus } from '../../enums/orderStatus';

const OrderDraggable: React.FC = () => {
    const [openOrders, setOpenOrders] = useState<boolean>(false);
    const { pathname } = useLocation();
    const { data: orders } = useDataLastOrders(true);
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const handleStart = () => {
        setIsDragging(false); 
    };

    const handleDrag = () => {
        setIsDragging(true); 
    };

    const handleStop = (e: DraggableEvent, data: DraggableData, item?: { documentNumber: string }) => {
        if (!isDragging) {
            if (item) {
                navigate(`${URLS.DOCUMENTS_ITEM.LINK}/${item.documentNumber}`);
            } else {
                setOpenOrders(!openOrders); 
            }
        }
        setIsDragging(false);
    };

    return (
        <>
            {(pathname.includes(URLS.CATALOG.LINK) || pathname.includes(URLS.PRODUCT_PAGE.LINK)) &&
                <>
                    {orders && orders.length > 0 &&
                        <>
                            {!openOrders && orders.length > 8 ?
                                <Draggable onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
                                    <Paper
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '70px',
                                            height: '70px',
                                            borderRadius: '50%',
                                            background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                            color: 'white',
                                            position: 'relative',
                                            boxShadow: 3,
                                            border: '2px solid white'
                                        }}
                                        onClick={() => setOpenOrders(!openOrders)}
                                    >
                                        <img src={`${process.env.REACT_APP_MEDIA}/order.svg`} alt="icon" width={'35px'} height={'35px'} />
                                        <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                            {`${orders.length}`}
                                        </Typography>
                                        <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                            {'הזמנות'}
                                        </Typography>
                                    </Paper>
                                </Draggable>
                                :
                                (openOrders || orders.length < 8) && orders.map((item) =>
                                    <Draggable key={item.documentNumber} onStart={handleStart} onDrag={handleDrag} onStop={(e, data) => handleStop(e, data, item)}>
                                        <Paper
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '70px',
                                                height: '70px',
                                                borderRadius: '50%',
                                                background: 'linear-gradient(135deg, #00bcd4, #009688)',
                                                color: 'white',
                                                position: 'relative',
                                                boxShadow: 3,
                                                border: '2px solid white'
                                            }}
                                        >
                                            <img src={`${process.env.REACT_APP_MEDIA}/${item.status === OrderStatus.Delivered ? 'orderComplete' : 'order'}.svg`} alt="icon" width={'35px'} height={'35px'} />
                                            <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                                {'הזמנה'}
                                            </Typography>
                                            <Typography fontSize={'10px'} fontWeight={700} lineHeight={'8px'} textAlign={'center'}>
                                                {`${item.documentNumber.slice(-4)}`}
                                            </Typography>
                                        </Paper>
                                    </Draggable>
                                )
                            }
                        </>
                    }
                </>
            }
        </>
    );
};

export default OrderDraggable;
