import { Box } from '@mui/material';
import React from 'react';
import Notifications from '../components/Notifications';
import Layout from '../components/Layout';

const NotificationsPage = () => {
    return (
        <Layout widhoutPadding={false}>
            <Notifications.List/>
        </Layout>
    );
};

export default NotificationsPage;