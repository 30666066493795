import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { PermissionService } from '../services/permissions.service'

const fetchData = async (
  userId: string | number,
): Promise<IPermission[]> => {
  return await PermissionService.getPermissions(userId)
}

const useDataPermission = () => {
  const { user } = useAuth()

  const { data, error, isLoading, mutate } = useSWR<IPermission[]>(
    `/client/permissions/${user?.id}`,
    () => fetchData(user?.id!)
  )

  const update = async (id:number, field: string, value: boolean) => {
    await PermissionService.updatePermission(id,field,value)
    mutate()
  }

  const create = async (user: IUser, title: string, color:string) => {
    await PermissionService.createPermission(user,title,color)
    mutate()
  }

  return {
    data,
    isLoading: isLoading,
    isError: error,
    update,
    create,
    mutate,
  }
}

export default useDataPermission

