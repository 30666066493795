import useSWR from 'swr'
import { FaqService } from '../services/faq.service'

const fetchData = async (
): Promise<IFaq[]> => {
  return await FaqService.getFaq()
}

const useDataFaq = () => {
  const { data, error, isLoading, mutate } = useSWR<IFaq[]>(
    `/faq`,
    () => fetchData()
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataFaq

