import { Box, Card, Skeleton } from '@mui/material';
import React from 'react';

const CardSkeleton = () => {
    return (
        <Box sx={{display:'flex', gap:'10px', justifyContent:'space-between', padding:'15px'}}>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '15px',
                    width: '100px',
                    height: '100px',
                    flexShrink: 0 
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Skeleton variant="text" sx={{ width: '100%', height:'44px' }} />
            </Box>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '15px',
                    width: '35px',
                    height: '35px',
                    flexShrink: 0,
                    mt:'50px'
                }}
            />

        </Box>
    );
};

export default CardSkeleton;