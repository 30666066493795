import { create } from 'zustand';
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware';

interface serviceState {
    selectedTags: string[];
    setSelectedTags: (tag: string) => void; 
    openText: boolean
    setOpenText: (openText: boolean) => void
}

export const useService = create(
  persist(
    (set) => ({
        openText:false,
        setOpenText:(openText) => set({openText}),
        selectedTags: [],
        setSelectedTags: (tag) => set((state) => {
            const { selectedTags } = state;
            if (selectedTags.includes(tag)) {
                return { selectedTags: selectedTags.filter(t => t !== tag) };
            } else {
                return { selectedTags: [...selectedTags, tag] };
            }
        }),
    }),
    {
      name: 'service-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<serviceState, serviceState>
  )
);
