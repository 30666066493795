export enum OrderStatus {
    Online = 'OnLine',
    AvailableForAssignment = 'ניתן לשיבוץ!',
    ApprovedForExecution = 'מאושר לבצוע!',
    Delivered = 'סופקה',
    PartiallyDelivered = 'סופקה חלקית',
    Canceled = 'מבוטלת',
}

export const handleStatus = (status: string): string => {
    switch (status) {
        case OrderStatus.Online:
            return 'הזמנתך התקבלה';
        case OrderStatus.AvailableForAssignment:
            return 'הזמנתך בטיפול';
        case OrderStatus.ApprovedForExecution:
            return 'הזמנתך בדרך אליך';
        case OrderStatus.Delivered:
            return 'הזמנתך סופקה';
        case OrderStatus.PartiallyDelivered:
            return 'הזמנתך סופקה'; 
        case OrderStatus.Canceled:
            return 'הזמנתך בוטלה';
        default:
            return status;
    }
};