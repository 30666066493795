import useSWR from 'swr'
import { SliderService } from '../services/slider.service'

const fetchData = async (
): Promise<ISlider[]> => {
  return await SliderService.getSliders()
}

const useDataSlider = () => {
  const { data, error, isLoading, mutate } = useSWR<ISlider[]>(
    `/client/slider`,
    () => fetchData()
  )

  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataSlider

