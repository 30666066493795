import React from 'react';
import useDataDocumentItems from '../../hooks/useDataDocumentItems';
import { useParams } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import Card from './Card';
import { useDocuments } from '../../store/documents.store';
import { useCart } from '../../store/cart.store';
import CatalogCard from '../Catalog/CatalogCard';
import CardSkeleton from './CardSkeleton';

const List = () => {
    const { id } = useParams();
    const { data, isLoading } = useDataDocumentItems(id!);
    const { openEdit, loading } = useDocuments();
    const { cart } = useCart();
    console.log('loading',loading)
    return (
        <Box>
            {(loading || isLoading) ? (
                // Display multiple skeletons with dividers while loading
                [...Array(5)].map((_, index) => (
                    <Box key={index}>
                        <CardSkeleton />
                        {index < 4 && <Divider />} {/* Adding divider between skeletons */}
                    </Box>
                ))
            ) : (
                openEdit ? (
                    <>
                        {cart?.map((item, index) => (
                            <Box key={item.product.id}>
                                <CatalogCard item={item.product} />
                                {index < cart.length - 1 && <Divider />} {/* Adding divider between cart items */}
                            </Box>
                        ))}
                    </>
                ) : (
                    <>
                        {data?.items?.map((item, index) => (
                            <Box key={index}>
                                <Card item={item} />
                                {index < data.items.length - 1 && <Divider />} {/* Adding divider between data items */}
                            </Box>
                        ))}
                    </>
                )
            )}
        </Box>
    );
};

export default List;
