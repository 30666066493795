import { Box, Card, IconButton, Skeleton } from '@mui/material';
import React from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const CardSkeleton = () => {
    return (
        <Card sx={{display:'flex', justifyContent:'space-between', margin:'10px', padding:'10px 15px'}}>
            <Skeleton variant="text" sx={{ width: '70%', height:'44px' }} />
            <Box sx={{display:'flex'}}>
                <IconButton>
                    <RemoveRedEyeOutlinedIcon color='primary'/>
                </IconButton>
                <IconButton>
                    <FileDownloadOutlinedIcon color='primary'/>
                </IconButton>
            </Box>
        </Card>
    );
};

export default CardSkeleton;