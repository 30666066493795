import { Box } from '@mui/material';
import React from 'react';
import Layout from '../components/Layout';
import MenuNavigation from '../components/MenuNavigation';

const MenuPage = () => {
    return (
        <Layout>
            <MenuNavigation.Profile/>
            <MenuNavigation.ListMenu/>
        </Layout>
    );
};

export default MenuPage;