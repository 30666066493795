import { Box, Typography } from '@mui/material';
import React from 'react';
import CardMachine from './CardMachine';
import CardSkeleton from './CardSkeleton';
import useDataMachines from '../../hooks/useDataMahines';

const List = () => {
    const { data, isLoading } = useDataMachines();

    return (
        <Box>
            <Box sx={{ padding: '10px' }}>
                <Typography variant='h5' color='#154D72' fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                    המכונות שלי
                </Typography>
                {data && data.length > 0 && (
                    <Typography variant='body2' color='#436F7C' fontSize={'13px'} fontWeight={400}>
                        לחצו על כרטיסיה כדי לפתוח קריאת שירות
                    </Typography>
                )}
            </Box>
            
            {isLoading ? (
                [...Array(5)].map((_, index) => (
                    <CardSkeleton key={index} />
                ))
            ) : (
                data?.map((item) => (
                    <CardMachine key={item.product.id} item={item} />
                ))
            )}
        </Box>
    );
};

export default List;
