import React from 'react';
import Layout from '../components/Layout';
import Documents from '../components/Documents';
import SecondPageLayout from '../components/Layout/SecondPageLayout';

const DocumentsPage = () => {

    return (
        <SecondPageLayout withFooter={true}>
            <Documents.Filter/>
            <Documents.List/>
        </SecondPageLayout>
    );
};

export default DocumentsPage;