import { Box, TextField } from '@mui/material';
import React from 'react';

const Filter = () => {
    return (
        <Box sx={{padding:'0 10px'}}>
            <TextField id="outlined-basic" placeholder='חפשו לפי שם מכונה, מס״ד, סניף' variant="outlined" fullWidth/>
        </Box>
    );
};

export default Filter;