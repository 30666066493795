import { Box, Container, IconButton, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useNavigate } from 'react-router-dom';
import FooterNavigation from './FooterNavigation';
import FooterItemAddToCart from '../Catalog/FooterItemAddToCart';
const SecondPageLayout = ({children, withFooter, addToCartFooter}:{children:React.ReactNode, withFooter?: boolean, addToCartFooter?:boolean}) => {
    const navigate = useNavigate()
    return (
        <Container 
            sx={{ 
                minHeight: '100vh', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between', 
                py: '50px', 
                px: '20px',
            }}
        >
            <Box sx={{display:"flex", alignItems:'center'}} onClick={() => navigate(-1)}>
                <IconButton>
                    <ArrowForwardIosRoundedIcon sx={{color:'#154D72'}}/>
                </IconButton>
                <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                חזרה
                </Typography>
        
            </Box>
            <Box sx={{ flexGrow: 1, paddingTop:'20px', paddingBottom:withFooter ? '50px': null}}> 
                {children}
            </Box>
            {withFooter &&
                <Box
                    component="footer"
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1100, 
                    }}
                >
                    <FooterNavigation />
                </Box>
            }
            { addToCartFooter &&
                    <Box
                        component="footer"
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1100, 
                        }}
                    >
                        <FooterItemAddToCart />
                    </Box>
            
            }
        </Container>
    );
};

export default SecondPageLayout;