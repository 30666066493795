import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { AreaService } from '../services/area.service'
import { AvatarService } from '../services/avatar.service'

const fetchData = async (
  userId: number,
): Promise<IAvatar[]> => {
  return await AvatarService.getAvatars(userId)
}

const useDataAvatar = () => {
  const { user } = useAuth()
  const { data, error, isLoading, mutate } = useSWR<IAvatar[]>(
    `/api/avatarsUser/${user?.id}`,
    () => fetchData(user?.id!)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataAvatar

