import React from 'react';
import useDataDocuments from '../../hooks/useDataDocuments';
import { Box, CircularProgress, Typography } from '@mui/material';
import CardDoc from './CardDoc';
import CardSkeleton from './CardSkeleton';

const List = () => {
    const { data, isLoading } = useDataDocuments();
    console.log('data', data);

    return (
        <Box display="flex" flexDirection="column">
            {isLoading ? (
                // Show multiple skeletons while loading
                [...Array(8)].map((_, index) => (
                    <CardSkeleton key={index} />
                ))
            ) : (
                <>
                    {data?.map((item) => (
                        <CardDoc key={item.documentNumber} item={item} />
                    ))}
                    {data && data.length === 0 && (
                        <Box 
                            mt="auto" 
                            display="flex" 
                            justifyContent="center" 
                            alignItems="center"
                            width={'100%'}
                        >
                            <Box sx={{position:'absolute', bottom:'60px'}}>
                                <Box>
                                    <Typography textAlign={'center'}>
                                        אופס!
                                    </Typography>
                                    <Typography textAlign={'center'}>
                                        נראה שעוד אין פה כלום
                                    </Typography>
                                    <img 
                                        src={`${process.env.REACT_APP_MEDIA}/emptyDoc.png`} 
                                        alt="Empty Document" 
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default List;
