import { Box, Container } from '@mui/material';
import React from 'react';
import backgroundGif from '../../styles/bg.gif';

const AuthLayout = ({children}:{children:React.ReactNode}) => {
    return (
        <Box 
            sx={{
                position: 'relative',
                height: '100vh',
                width: '100vw',
                overflow: 'hidden',
                background: `url(${backgroundGif}) no-repeat center center fixed`,
                backgroundSize:'cover', 
            }}
        >
            <Container
                sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'space-between', 
                    py: '50px', 
                    px: '20px' 
                }}
            >
                {children}
            </Container>
        </Box>
    );
};

export default AuthLayout;