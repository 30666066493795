import { Box, Divider, Typography, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import useDataArea from '../../hooks/useDataArea';
import { useDocuments } from '../../store/documents.store';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import CustomCheckbox from '../Common/CUstomCheckBox';

const ChoosedArea = () => {
    const { data } = useDataArea();
    const { choosedAreas, setChoosedArea } = useDocuments();
    const navigate = useNavigate();

    // State to store the randomly selected image URL
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        const images = [
            `${process.env.REACT_APP_MEDIA}/ChooseAreaIlu.png`,
            `${process.env.REACT_APP_MEDIA}/ChooseAreaIlu2.png`,
            `${process.env.REACT_APP_MEDIA}/ChooseAreaIlu3.png`,
        ];
        // Randomly select one image from the array
        const randomImage = images[Math.floor(Math.random() * images.length)];
        setImageSrc(randomImage);
    }, []);

    return (
        <Box>
            <Typography textAlign={'center'} color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'22px'}> 
                קריאות שירות
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt:'20px' }}>
                <img src={imageSrc} alt="Choose Area Illustration" />
            </Box>
            <Divider />
            <Box sx={{ padding: '15px 30px' }}>
                <Typography color='#154D72' fontSize={'15px'} fontWeight={700} lineHeight={'21px'}>
                    בחרו סניף לטעינת המסמכים
                </Typography>
                <Typography color='#436F7C' fontSize={'13px'} fontWeight={400}>
                    טעינת המסמכים עלולה לערוך מספר דקות
                </Typography>

                <FormGroup sx={{ paddingTop: '30px' }}>
                    {data?.map((area) => (
                        <FormControlLabel
                            key={area.code}
                            control={
                                <CustomCheckbox
                                    checked={choosedAreas.some(chosen => chosen.code === area.code)}
                                    onChange={() => setChoosedArea(area)}
                                />
                            }
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    color: '#436F7C', 
                                    fontSize: '13px',
                                    fontWeight: 400,
                                    lineHeight: '19px',
                                }
                            }}
                            label={`${area.title} - ${area.address}`}
                        />
                    ))}
                </FormGroup>
            </Box>
            <Box sx={{ padding: '10px 20px' }}>
                <Button
                    fullWidth
                    variant='contained'
                    onClick={() => navigate(URLS.DOCUEMNTS_MENU.LINK)}
                >
                    המשך
                </Button>
            </Box>
        </Box>
    );
};

export default ChoosedArea;
