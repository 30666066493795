
export enum ServiceStatus {
    Online = 'OnLine',
    ToBeExecuted = 'לביצוע!',
    ForAssignment = 'לשיבוץ',
    Draft = 'טיוטא',
    Final = 'סופית',
    Canceled = 'מבוטלת',
    RepairInLab = 'תיקון מעבדה',
    Open = 'פתוחה',
}

export const handleColor = (status: ServiceStatus) => {
    switch (status) {
        case ServiceStatus.Online:
            return <span className='tag_green' style={{ color: '#34c840' }}>{'קריאתך התקבלה'}</span>;
        case ServiceStatus.ToBeExecuted:
            return <span className='tag_green' style={{ color: '#34c840' }}>{'טכנאי בדרך אליך'}</span>;
        case ServiceStatus.ForAssignment:
            return <span className='tag_green' style={{ color: '#34c840' }}>{'קריאתך התקבלה'}</span>;
        case ServiceStatus.Draft:
            return <span className='tag_green' style={{ color: '#959595' }}>{'טיוטא'}</span>;
        case ServiceStatus.Final:
            return <span className='tag_green' style={{ color: '#34c840' }}>{'הטיפול בקריאה הסתיים'}</span>;
        case ServiceStatus.Canceled:
            return <span className='tag_green' style={{ color: '#959595' }}>{'הקריאה בוטלה'}</span>;
        case ServiceStatus.RepairInLab:
            return <span className='tag_green' style={{ color: '#34c840' }}>{'קריאתך בטיפול'}</span>;
        default:
            return <span className='tag_green' style={{ color: '#F2B84D' }}>{'פתוחה'}</span>;
    }
};
