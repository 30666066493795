import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { ServicesService } from '../services/services.service'
import { useArea } from '../store/area.store'
import { useParams } from 'react-router-dom'

const fetchData = async (
  documentNumber: string,
): Promise<IDocument> => {
  return await ServicesService.getDocumentSevice(documentNumber)
}

const useDataMachineDocument = () => {
  const { id } = useParams()
  const { data, error, isLoading, mutate } = useSWR<IDocument>(
    `/client/service/${id}`,
    () => fetchData(id!)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataMachineDocument

