import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import useDataSlider from '../../hooks/useDataSlider';
import Carousel from "react-material-ui-carousel";
import { themeColors } from '../../styles';

const Slider = () => {
    const { data } = useDataSlider();

    return (
        <Box>
            <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
            מה חדש?
            </Typography>
            <Carousel 
                navButtonsAlwaysVisible={true}
                navButtonsAlwaysInvisible={true}
            >
                {data?.map((item) => (
                    <Box key={item.id} sx={{ position: 'relative', height: 200 }}> 
                        <Card sx={{ height: '100%', overflow: 'hidden' }}>
                            <CardMedia
                                component="img"
                                height="100%"
                                image={`${process.env.REACT_APP_MEDIA_BACKEND}media/${item.image.source}/${item.image.filePath}`} 
                                alt={item.title}
                                sx={{ objectFit: 'cover' }} 
                            />
                            <CardContent
                                sx={{
                                    position: 'absolute',
                                    bottom: 0, 
                                    left: 0,
                                    right: 0,
                                    padding: '16px', 
                                    borderRadius: '20px', 
                                    background: `linear-gradient(180deg, #003A4E 22.35%, rgba(0, 58, 78, 0.90) 70.2%), 
                                                linear-gradient(0deg, rgba(0, 58, 78, 0.20) 0%, rgba(0, 58, 78, 0.20) 100%), 
                                                lightgray 50% / cover no-repeat`,
                                    backgroundBlendMode: 'normal, color, normal', 
                                    color: 'white', 
                                }}
                            >
                                <Typography variant="h5" component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {item.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Carousel>
        </Box>
   
    );
};

export default Slider;
