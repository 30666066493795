// Global
import { Box } from '@mui/material';
import React, {
    FC,
    createContext,
    useContext,
    ReactNode,
  } from 'react'
import Draggbale from '../components/Draggbale';
  
  // Defines
  interface DraggableContextType {

  }
  const DraggableContext = createContext<DraggableContextType | null>(null)
  
  // React hook
  const useDraggable = () => {
    const context = useContext(DraggableContext)
    if (!context) {
      throw new Error('Can not run without "DraggableContext Provider"')
    }
    return context
  }
  
  interface DraggablesProviderProps {
    children: ReactNode
  }
  const DraggablesProvider: FC<DraggablesProviderProps> = ({ children }) => {
    const value = {
   
    }
  
    return (
      <DraggableContext.Provider value={value}>
        <Box sx={{position:'fixed', bottom:'120px', zIndex:999}}> 
          <Draggbale.OrderDraggable/>
          <Draggbale.ServicesDraggable/>
          <Draggbale.EditOrderDraggable/>
        </Box>
        {children}
      </DraggableContext.Provider>
    )
  }
  
  export { useDraggable, DraggablesProvider }
  