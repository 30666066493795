import axios from 'axios'

interface MediaObjectResponse {
  '@id': string
  contentUrl: string
  createdAt: string
  source: string
  filePath: string
}

export const MediaObjectService = {
  async uploadImage(file: File, source: string): Promise<MediaObjectResponse> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('source', source)
    const response = await axios.post(
      `${process.env.REACT_APP_API}/api/media_objects`,
      formData
    )
    return response.data
  },


}
