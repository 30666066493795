import { Avatar, Backdrop, Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useRef, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useAuth } from '../../store/auth.store';
import ModalWrapper from '../Modals/ModalWrapper';
import useDataAvatar from '../../hooks/useDataAvatar';
import CardAvatar from './CardAvatar';
import { useAvatarStore } from '../../store/avatar.store';
import { styled } from '@mui/material/styles';
import { MediaObjectService } from '../../services/media.service';
import { AvatarService } from '../../services/avatar.service';
import { useNotify } from '../../provider/NotifyProvider';
import AreaSelector from '../../utils/AreaSelector';

const Profile = () => {
    const [loading, setLoading] = useState(false)
    const { user, updateAvatar } = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const { data } = useDataAvatar();
    const { choosedAvatar, setChoosedAvatar, avatarId} = useAvatarStore();
    const [file, setFile] = useState<File | null>()
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const {onErrorNotify, onSuccessNotify} = useNotify()

    const handleClick = () => {
        fileInputRef.current?.click();
    };


    
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setFile(file)
            const imageUrl = URL.createObjectURL(file);
            setChoosedAvatar(imageUrl); 
        }
    };

    const save = async () => {
        try {
            setLoading(true)
            let response = null
            if(file){
                response = await MediaObjectService.uploadImage(file,'avatar')
            }
            if(user){
                const response2 = await AvatarService.saveAvatar(response ? response['@id'] : avatarId! ,user.id!)
                if(response2.status === 'success') {
                    onSuccessNotify(response2.message)
                    updateAvatar(response2.data)
                } else {
                    onErrorNotify(response2.message)
                }
            } 
        } catch(e) {
            console.log('[ERROR]',e)
        } finally {
            setLoading(false)
            setOpenModal(false)
            setChoosedAvatar('')
            setFile(null)
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative'
            }}
        >
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ position: 'relative' }}>
                <IconButton
                    onClick={() => setOpenModal(true)}
                    sx={{
                        position: 'absolute',
                        right: '20px',
                        zIndex: 999,
                        bgcolor: 'white',
                        boxShadow: '0px 8.8px 19.2px 0px #0E32211A',
                        '&:hover': { bgcolor: 'white' },
                        width: '35px',
                        height: '35px'
                    }}>
                    <AddRoundedIcon color='primary' />
                </IconButton>
                        
                <Box sx={{justifyContent:'center', alignItems:'center', display:'flex'}}>
                    <Avatar
                        alt="Remy Sharp"
                        
                        src={(user?.avatar?.image?.filePath && `${process.env.REACT_APP_MEDIA_BACKEND}media/avatar/${user?.avatar?.image?.filePath}`) || (choosedAvatar ? `${choosedAvatar}` : `${process.env.REACT_APP_MEDIA}/avatarPlaceholder.png`)}
                        sx={{ width: 130, height: 130 }}
                    />
                </Box>
                <Typography sx={{mt:'7px'}} fontWeight={700} fontSize={'12px'} textAlign={'center'} color='#154D72' lineHeight={'16.8px'}>
                    מס׳ לקוח {user?.extId}
                </Typography>
                <Typography fontSize={'20px'} fontWeight={800} textAlign={'center'} color='#154D72' lineHeight={'28px'}>
                    {user?.name}
                </Typography>
                <Box>
                    <AreaSelector/>
                </Box>
            </Box>

            <ModalWrapper
                active={openModal}
                setActive={setOpenModal}
                component={
                    <Typography fontSize={'20px'} fontWeight={700} color='#154D72' lineHeight={'28px'}>
                        שינוי תמונת פרופיל
                    </Typography>
                }
            >
                <Box>
                    <Box>
                        <Typography fontWeight={400} lineHeight={'19.5px'} color='#436F7C'>
                            העלו קבצים מסוג ,GIF, PNG או JPG <br />
                            או בחרו אייקון מהמאגר למטה
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin:'30px 0' }}>
                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                                onClick={handleClick}
                                sx={{
                                    position: 'absolute',
                                    right: '0px',
                                    zIndex: 999,
                                    bgcolor: 'white',
                                    boxShadow: '0px 8.8px 19.2px 0px #0E32211A',
                                    '&:hover': { bgcolor: 'white' },
                                    width: '35px',
                                    height: '35px'
                                }}>
                                <AddRoundedIcon color='primary' />
                            </IconButton>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Avatar
                                alt="Remy Sharp"
                                src={(choosedAvatar && `${choosedAvatar}`) || (user?.avatar?.image?.filePath && `${process.env.REACT_APP_MEDIA_BACKEND}media/avatar/${user?.avatar?.image?.filePath}`) ||  (`${process.env.REACT_APP_MEDIA}/avatarPlaceholder.png`)}
                                sx={{ width: 130, height: 130 }}
                            />
                        </Box>
                    </Box>
                    <Grid container spacing={2}>
                        {data?.map((item, key) => 
                            <Grid key={key} size={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CardAvatar item={item} />
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{display:'flex', gap:'20px'}}>
                        <Button variant='outlined' fullWidth>
                        ביטול
                        </Button>
                        <Button variant='contained' fullWidth onClick={() => save()}>
                        עדכון תמונה
                        </Button>
                    </Box>  
                </Box>
            </ModalWrapper>
        </Box>
    );
};

export default Profile;
