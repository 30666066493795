
const URLS = {
    AuthHome: {
        LINK: '/auth',
        LABEL: '',
        ROUTER: '/auth',
    },
    AuthLogin: {
        LINK: '/login',
        LABEL: '',
        ROUTER: '/login',
    },
    AuthStepOne: {
        LINK: '/stepOne',
        LABEL: '',
        ROUTER: '/stepOne',
    },
    AuthStepTwo: {
        LINK: '/stepTwo',
        LABEL: '',
        ROUTER: '/stepTwo',
    },
    AuthStepThree: {
        LINK: '/stepThree',
        LABEL: '',
        ROUTER: '/stepThree',
    },
    AREA_CHOOSE: {
        LINK: '/areaChoosed',
        LABEL: '',
        ROUTER: '/areaChoosed',
    },
    RESET_PASSWORD_STEP_ONE: {
        LINK: '/resetPasswordOne',
        LABEL: '',
        ROUTER: '/resetPasswordOne',
    },
    RESET_PASSWORD_STEP_TWO: {
        LINK: '/resetPasswordTwo',
        LABEL: '',
        ROUTER: '/resetPasswordTwo',
    },
    RESET_PASSWORD_STEP_THREE: {
        LINK: '/resetPasswordThree',
        LABEL: '',
        ROUTER: '/resetPasswordThree',
    },
    HOME: {
        LINK: '/',
        LABEL: '',
        ROUTER: '/',
    },
    CATALOG: {
        LINK: '/catalog',
        LABEL: '',
        ROUTER: '/catalog',
    },
    PRODUCT_PAGE: {
        LINK: '/product',
        LABEL: '',
        ROUTER: '/product/:sku',
    },
    CART: {
        LINK: '/cart',
        LABEL: '',
        ROUTER: '/cart',
    },
    MENU_PAGE: {
        LINK: '/menu',
        LABEL: '',
        ROUTER: '/menu', 
    },
    DOCUEMNTS_MENU: {
        LINK: '/docmenu',
        LABEL: '',
        ROUTER: '/docmenu',
    },
    DOCUEMNTS_MENU_CHOOSE_AREA: {
        LINK: '/docmenuArea',
        LABEL: '',
        ROUTER: '/docmenuArea',
    },
    DOCUMENTS: {
        LINK: '/documents',
        LABEL: '',
        ROUTER: '/documents/:documentType',
    },
    DOCUMENTS_ITEM: {
        LINK: '/documentItems',
        LABEL: '',
        ROUTER: '/documentItems/:id',
    },
    AFTER_ORDER: {
        LINK: '/',
        LABEL: '',
        ROUTER: '/',
    },
    BUSSNIES: {
        LINK: '/bussniesPage',
        LABEL: '',
        ROUTER: '/bussniesPage',
    },
    PERMISSION: {
        LINK: '/permissions',
        LABEL: '',
        ROUTER: '/permissions',
    },
    USER_TYPE_CREATE: {
        LINK: '/',
        LABEL: '',
        ROUTER: '/',
    },
    SETTINGS: {
        LINK: '/',
        LABEL: '',
        ROUTER: '/',
    },
    FAQ: {
        LINK: '/faq',
        LABEL: '',
        ROUTER: '/faq',
    },
    NOTIFICATIONS: {
        LINK: '/notification',
        LABEL: '',
        ROUTER: '/notification',
    },
    SUPPORT: {
        LINK: '/support',
        LABEL: '',
        ROUTER: '/support',
    },
    SERVICE: {
        LINK: '/service',
        LABEL: '',
        ROUTER: '/service',
    },
    MACHINE_PAGE: {
        LINK: '/machinePage',
        LABEL: '',
        ROUTER: '/machinePage/:serialNumber/:sku',
    },
    SERVICE_DOCUMENT_PAGE: {
        LINK: '/serviceDocumentPage',
        LABEL: '',
        ROUTER: '/serviceDocumentPage/:id',
    }

}

export default URLS;