import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface usePermissionStoreState {
    choosedPermission: IPermission | null,
    setChoosedPermission: (choosedPermission: IPermission) => void

}

export const usePermissionStore = create(
  persist(
    (set, get) => ({
        choosedPermission: null,
        setChoosedPermission: (choosedPermission) => set({choosedPermission}),
    }),
    {
      name: 'permission-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<usePermissionStoreState, usePermissionStoreState>
  )
)
