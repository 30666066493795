import React from 'react';
import Layout from '../components/Layout';
import Home from '../components/Home';

const HomePage = () => {

  return (
    <Layout>
        <Home.LastNews/>
        <Home.Slider/>
        <Home.LastOrders/>
        <Home.LastServices/>
    </Layout>
 
  );
};

export default HomePage;
