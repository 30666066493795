import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface AreaState {
    selectedProduct: IProduct | null,
    setSelectedProduct: (selectedProduct: IProduct | null) => void
}

export const useProduct = create(
  persist(
    (set, get) => ({
        selectedProduct: null,
        setSelectedProduct: (selectedProduct) => set({selectedProduct}),
    }),
    {
      name: 'product-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<AreaState, AreaState>
  )
)
