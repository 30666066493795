import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCart } from '../../store/cart.store';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { useArea } from '../../store/area.store';
import { useNotify } from '../../provider/NotifyProvider';

const Calendar = () => {
    const {deliveryDate, setDeliveryDate, sendOrder} = useCart()
    const navigate = useNavigate()
    const {user} = useAuth()
    const {choosedArea} = useArea()
    const {onErrorNotify, onSuccessNotify} = useNotify()

    const handleSendOrder =  () => {
        if(!user?.permission?.permissionDetaileds[0]?.isOrderable ){
            onErrorNotify('אין הרשאה לבצע הזמנה') 
            return
        }
        sendOrder(user!,deliveryDate,choosedArea!).then((e) => {
            if(e?.status === 'success'){
                navigate(`${URLS.DOCUMENTS_ITEM.LINK}/${e.data?.documentNumber}`)
                onSuccessNotify(e.message)
            } else {
                onErrorNotify(e.message)
            }
        })
    }

    return (
        <Box sx={{padding:'20px'}}>
            <Typography fontWeight={700} fontSize={'15px'} color='#154D72'>
            בחרו תאריך למשלוח 
            </Typography>
            <Typography fontWeight={400} fontSize={'15px'} color='#154D72'>
            בחרו תאריך מתוך התאריכים הזמינים
            </Typography>
            <Box sx={{margin:'20px 0'}}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker value={moment(deliveryDate)} onChange={(e) => setDeliveryDate(new Date(e?.toString()!))}/>
                </DemoContainer>
            </Box>
            <Box sx={{marginBottom:'20px'}}>
                <TextField multiline rows={2} placeholder='הוסיפו הערה...' fullWidth/>
            </Box>
            <Button 
                variant='contained' 
                fullWidth 
                onClick={() => handleSendOrder()}
            > 
            אישור הזמנה
            </Button>
        </Box>
    );
};

export default Calendar;