import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDocuments } from '../../store/documents.store';
import useDataDocuments from '../../hooks/useDataDocuments';
import { DocumentEnum } from '../../types/documentEnum';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const Filter = () => {
    const {documentType} = useParams()
    const {dateFrom, dateTo, setDateFrom, setDateTo} = useDocuments()
    const handleTitle = () => {
        if(documentType == DocumentEnum.SERVICES){
            return 'קריאות שירות'
        } else if(documentType == DocumentEnum.ORDERS){
            return 'הזמנות'
        } else if(documentType == DocumentEnum.DELIVERY){
            return 'תעודות משלוח'
        } else if(documentType == DocumentEnum.INVOICES){
            return 'קבלות'
        } else if(documentType == DocumentEnum.INVOICES_MAS){
            return 'חשבוניות מס'
        } else if(documentType == DocumentEnum.INVOICES_DEAL){
            return 'חשבוניות עסקה'
        }
    }

    return (
        <Box sx={{padding:'0 10px'}}>
            <Box sx={{display:'flex', gap:'5px'}}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Component 2">
                    <path
                    id="Icon"
                    d="M14 11H8M10 15H8M16 7H8M20 6.8V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H15.2C16.8802 2 17.7202 2 18.362 2.32698C18.9265 2.6146 19.3854 3.07354 19.673 3.63803C20 4.27976 20 5.11984 20 6.8Z"
                    stroke="#2F4B6C"
                    style={{ stroke: "#2F4B6C", strokeOpacity: 1 }}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    />
                </g>
                </svg>
                <Typography variant='h5' color='#154D72' fontSize={'20px'} lineHeight={'28px'} fontWeight={700}>
                    {handleTitle()}
                </Typography>
            </Box>
            <Box sx={{display:'flex', gap:'10px', justifyContent:'center'}}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker value={moment(dateFrom)} onChange={(e) => setDateFrom(new Date(e?.toString()!))}/>
                </DemoContainer>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker value={moment(dateTo)} onChange={(e) => setDateTo(new Date(e?.toString()!))}/>
                </DemoContainer>
            </Box>
        </Box>
    );
};

export default Filter;