import { Card, Typography } from '@mui/material';
import React, { FC } from 'react';

interface IAreaCard {
    item: IArea
}

const AreaCrad:FC<IAreaCard> = ({item}) => {
    return (
        <Card sx={{padding:'10px 15px', mt:'10px', borderRadius:'15px'}}>
            <Typography fontSize={'15px'} color={'#154D72'} fontWeight={700} lineHeight={'21px'}>
                {item?.title}
            </Typography>
            <Typography fontSize={'13px'} color={'#436F7C'} fontWeight={400} lineHeight={'19.5px'}>
                {item?.address}
            </Typography>
        </Card>
    );
};

export default AreaCrad;