import React, { FC, useState } from 'react';
import { ListItem, ListItemText, Collapse, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface IFaq {
    title: string;
    description: string;
}

interface CardProps {
    item: IFaq;
}

const Card: FC<CardProps> = ({ item }) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <>
            <ListItem onClick={handleToggle} component="li" sx={{ cursor: 'pointer' }}>
                <ListItemText 
                    primary={
                        <Typography
                            sx={{
                                color: '#154D72',
                                fontWeight: 700,
                                lineHeight: '21px',
                                fontSize: '15px',
                            }}
                        >
                            {item.title}
                        </Typography>
                    }
                />
                <IconButton>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <ListItem component="li">
                    <ListItemText
                        primary={
                            <Typography       
                                sx={{
                                    color: '#436F7C',
                                    fontWeight: 400,
                                    lineHeight: '21px',
                                    fontSize: '13px',
                                }}
                            >
                            {item.description}
                            </Typography>
                        }
                    />
                </ListItem>
            </Collapse>
        </>
    );
};

export default Card;
