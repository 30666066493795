import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface DocuemntsState {
    loading: boolean
    setLoading: (loading: boolean) => void
    dateFrom: Date,
    setDateFrom: (dateFrom: Date) => void
    dateTo: Date,
    setDateTo: (dateFrom: Date) => void
    choosedAreas: IArea[]
    setChoosedArea: (area: IArea) => void,
    openEdit: boolean
    setOpenEdit: (openEdit: boolean) => void
    choosedDocument: null | IDocument
    setChoosedDocument: (choosedDocument: IDocument | null) => void
}

export const useDocuments = create(
  persist(
    (set, get) => ({
        loading: false,
        setLoading: (loading) => set({loading}),
        dateFrom: new Date,
        setDateFrom: (dateFrom) => set({dateFrom}),
        dateTo: new Date,
        setDateTo: (dateTo) => set({dateTo}),
        choosedAreas:[],
        setChoosedArea: (area) => {
          const { choosedAreas } = get();
          set({
              choosedAreas: choosedAreas.some(a => a.code === area.code)
                  ? choosedAreas.filter(a => a.code !== area.code)
                  : [...choosedAreas, area],
          });
        },
        openEdit:false,
        setOpenEdit: (openEdit: boolean) => set({openEdit}),
        choosedDocument: null,
        setChoosedDocument: (choosedDocument) => set({choosedDocument})
    }),
    {
      name: 'documents-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<DocuemntsState, DocuemntsState>
  )
)
