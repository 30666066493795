import { Box, Card, IconButton, Skeleton } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CardSkeleton = () => {
    return (
        <Card 
        sx={{ 
            display: 'flex', 
            padding: '10px 15px', 
            borderRadius: '15px', 
            mt: '8px',
            gap: '10px', 
            }}>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '15px',
                    width: '100px',
                    height: '100px',
                    flexShrink: 0 
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Skeleton variant="text" sx={{ width: '100%', height:'44px' }} />
            </Box>
            <IconButton
                aria-label="show more"
                sx={{ marginLeft: 'auto', marginTop:'-50px' }}
            >
                <ExpandMoreIcon sx={{color:'#154D72'}}/>
            </IconButton>

        </Card>
    );
};

export default CardSkeleton;