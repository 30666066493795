import { Box, Button, Chip, Paper, Rating, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useDataQuestion from '../../hooks/useDataQuestion';
import { QuestionEnum } from '../../types/questionEnum';
import { QuestionService } from '../../services/question.service';
import { useNotify } from '../../provider/NotifyProvider';
import { useAuth } from '../../store/auth.store';

interface IQuestion {
    id: number;
    title: string;
    stars: number;
    type: string;
    extId: string;
}

const IlustrateOne = () => {
    const [value, setValue] = React.useState<number | null>(null);
    const { user } = useAuth()
    const [selectedChips, setSelectedChips] = React.useState<IQuestion[]>([]); 
    const { data } = useDataQuestion(QuestionEnum.ORDER);
    const [tags, setTags] = useState<IQuestion[]>([]);
    const [text, setText] = useState('');
    const {onErrorNotify, onSuccessNotify} = useNotify()

    const handleChipClick = (chip: IQuestion) => {
        setSelectedChips((prevSelected) =>
            prevSelected.some(item => item.id === chip.id)
                ? prevSelected.filter(item => item.id !== chip.id)
                : [...prevSelected, chip]
        );
    };

    const handleDelete = (chip: IQuestion) => {
        setSelectedChips((prevSelected) =>
            prevSelected.filter(item => item.id !== chip.id)
        );
    };

    const filterTheTags = () => {
        if (value) {
            const tags = data?.filter((item) => item.stars >= value);
            if (tags) {
                setTags(tags);
            }
        }
    };

    const handleSend =  () => {
        try {
            QuestionService.createQuestion(value ?? 0,selectedChips,text,user!, QuestionEnum.ORDER).then((e) => {
                if(e.status === 'success'){
                    onSuccessNotify(e.message)
                } else {
                    onErrorNotify(e.message)
                }
            })
        } catch(e) {
            console.log('[ERROR]',e)
        } 
    }

    useEffect(() => {
        filterTheTags();
    }, [value]);


    return (
        <Box>
            <Paper sx={{ bgcolor: '#27C9D4', textAlign: 'center', alignItems: 'center', padding: 2, borderRadius: '15px' }}>
                <Typography color="white" fontWeight={700} fontSize={15}>
                    דרגו את החוויה שלכם
                </Typography>
                <Rating
                    size="large"
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                />
                <Typography color="white">
                    אנא עזור לנו להשתפר,<br />
                    נשמח לשמוע ממה הייתם פחות מרוצים:
                </Typography>
                {tags?.map((chip) => (
                    <Chip
                        key={chip.id} 
                        label={chip.title}
                        onClick={() => handleChipClick(chip)}
                        variant={selectedChips.some(item => item.id === chip.id) ? "filled" : "outlined"}
                        color={selectedChips.some(item => item.id === chip.id) ? "primary" : "default"}
                    />
                ))}
                <TextField 
                    rows={3}
                    multiline
                    fullWidth
                    variant='outlined'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    sx={{
                        bgcolor: 'white',
                        borderRadius: '14px'
                    }}
                />
                <Box>
                    <Button variant='contained' onClick={handleSend}>
                        שליחה
                    </Button>
                </Box>
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <svg width="115" height="18" viewBox="0 0 115 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        d="M98.4521 -45.5996H18.5604C8.36169 -45.5996 0.106247 -37.3495 0.106247 -27.1874V-18.7495C0.106247 -8.57399 8.37509 -0.337288 18.5604 -0.337288H50.2192V15.9105C50.2192 17.3667 52.0063 18.0659 52.9944 16.9963L68.995 -0.323917H98.4521C108.637 -0.323917 116.906 -8.57399 116.906 -18.7495V-27.1874C116.906 -37.3495 108.637 -45.5996 98.4521 -45.5996Z"
                        fill="#27C9D4"
                        style={{ fill: "color(display-p3 0.1529 0.7882 0.8314)", fillOpacity: 1 }}
                    />
                </svg>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}> 
                <img src={`${process.env.REACT_APP_MEDIA}/two.png`} width={'120.12px'} height={'161.72px'} style={{ marginRight: '130px' }} />
            </Box>
    
        </Box>
    );
};

export default IlustrateOne;
