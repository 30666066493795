import React from 'react';
import Layout from '../components/Layout';
import { Box } from '@mui/material';
import Cart from '../components/Cart';

const CartPage = () => {
    return (
        <Layout>
            <Box>
                <Cart.Head/>
                <Cart.List/>
                <Cart.Calendar/>
            </Box>
        </Layout>
    );
};

export default CartPage;