export const ApiInterceptor = async <T>(url: string, options?: RequestInit): Promise<T> => {
    options = options || {};

    const isFormData = options.body instanceof FormData;

    // Check if it's a PATCH request
    const isPatchRequest = options.method?.toUpperCase() === 'PATCH';
    options.headers = {
        ...options.headers,
        ...(!isFormData && { 
            'Content-Type': isPatchRequest ? 'application/merge-patch+json' : 'application/json' 
        }), 
    };
    
    options.credentials = 'include';
    
    let response = await fetch(`${process.env.REACT_APP_API}${url}`, options);

    // if (response.status === 401) {
    //     await AuthService.getNewTokens(); 
    //     options.credentials = 'include';
    //     response = await fetch(url, options);
    // }

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json() as Promise<T>;
};
