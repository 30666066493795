import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import useDataLastOrders from '../../hooks/useDataLastOrders';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Home from '../Home';
import { themeColors } from '../../styles';

const LastOrders = () => {
    const {data} = useDataLastOrders()
    return (
        <Box>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mt:'20px'}}>
                <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                הזמנות שביצעת לאחרונה
                </Typography>
                <IconButton>
                    <ArrowBackIosRoundedIcon sx={{color:themeColors.title}}/>
                </IconButton>
            </Box>
      
            <Box>
                {data?.map((item) =>
                    <Home.Card item={item} isOrder={true}/>
                )}
            </Box>
        </Box>
    );
};

export default LastOrders;