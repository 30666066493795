import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    IconButton,
} from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';

const ResertPasswordStepOneForm = () => {
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const {restorePasswordStepOne} = useAuth()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IResetPasswordStepOne>()

    const onSubmit = async (data: IResetPasswordStepOne) => {
        setError('')
        try {
            const response = await restorePasswordStepOne(data.phone)
            if(response.status ==='success') {
                navigate(URLS.RESET_PASSWORD_STEP_TWO.LINK)
            } else {
                setError(response.message);
            }
        } catch(e) {
            setError("שגיאה");
        } 

    }
    
    return (

        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
                <Typography  fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                שחזור סיסמא
                </Typography>
                <Typography  variant="body1" color='#436F7C'>
                כדי שנוכל לשחזר את הסיסמא שלכם, הזינו את הטלפון איתו נרשמתם ונשלח לכם קישור איפוס.
                </Typography>
                <FormControl fullWidth margin="normal" sx={{mt:'30px'}}>
                    <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                        required: 'טלפון שדה חובה',
                        minLength: {
                            value: 10,
                            message: 'טלפון חייב להכיל לפחות 10 תווים',
                        },
                        }}
                        render={({ field }) => (
                        <TextField
                            {...field}
                            variant="outlined"
                            placeholder="טלפון"
                            type="tel"
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                        />
                        )}
                    />
                </FormControl>
                {error && (
                    <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>
                    {error}
                </Typography>
                )}
            </Box>
            <Box>
                <Button variant='contained' fullWidth type='submit'>
                המשך
                </Button>
            </Box>
        </form>
    );
};

export default ResertPasswordStepOneForm;