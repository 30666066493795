import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface AreaState {
    choosedArea: IArea | null,
    setChoosedArea: (choosedArea: IArea | null) => void
}

export const useArea = create(
  persist(
    (set, get) => ({
        choosedArea: null,
        setChoosedArea: (choosedArea) => set({choosedArea}),
    }),
    {
      name: 'area-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<AreaState, AreaState>
  )
)
