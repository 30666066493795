import { Box, Card, Skeleton } from '@mui/material';
import React from 'react';

const LastSkeleton = () => {
    return (
        <Card sx={{ 
            display: 'flex', 
            padding: '10px 15px', 
            borderRadius: '15px', 
            mt: '8px',
            gap: '10px', 
        }}>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '15px',
                    width: '100px',
                    height: '100px',
                    flexShrink: 0 
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Skeleton variant="text" sx={{ width: '100%', height:'44px' }} />
            </Box>
        </Card>
    );
};

export default LastSkeleton;