import { Box, Typography } from '@mui/material';
import React from 'react';

const Info = () => {
    return (
        <Box>
            <Box sx={{textAlign:'center'}}>
                <Typography color='#436F7C' fontSize={'13px'} fontWeight={700}>
                לא מוצאים תשובה לשאלה שלכם?
                </Typography>
                <Box sx={{display:'flex', gap:'5px',alignItems:'center',justifyContent:'center'}}>
                    <Typography color='primary' fontSize={'13px'} fontWeight={400} sx={{textDecoration:'underline'}}>
                    צרו איתנו קשר
                    </Typography>
                    <Typography color='#436F7C' fontSize={'13px'} fontWeight={400}>
                    ונדאג לענות לכם על הכל ✨
                    </Typography>
                </Box>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',mt:'13px'}}>
                <img src={`${process.env.REACT_APP_MEDIA}/faq.png`} />
            </Box>
        </Box>
    );
};

export default Info;