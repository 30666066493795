import { Box, Typography } from '@mui/material';
import React from 'react';
import { useProduct } from '../../store/product.store';
import { useCart } from '../../store/cart.store';


const CatalogItem = () => {
    const {selectedProduct} = useProduct()
    const {getCartItem} = useCart()
    const cart = getCartItem(selectedProduct!)

    return (
        <Box>
            <Box sx={{padding:'10px'}}>
                <img src={selectedProduct?.image ? `${process.env.REACT_APP_MEDIA_BACKEND}/${selectedProduct.image.filePath}` : `${process.env.REACT_APP_MEDIA}/placeholder.png`} width={'100%'} style={{borderRadius:'15px'}}/>
                <Box>
                    <Typography color='#154D72' fontSize={'20px'} fontWeight={700} lineHeight={'28px'}>
                        {selectedProduct?.title}
                    </Typography>
                    <Typography color='#436F7C' fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'}>
                        {selectedProduct?.packQuantity}
                    </Typography>
                    <Typography sx={{pt:'20px'}} fontWeight={400} lineHeight={'19.5px'} fontSize={'13px'} >
                        {selectedProduct?.description}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CatalogItem;