import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { themeColors } from '../../styles';

const LastOrders = () => {
    return (
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'10px'}}>
            <Typography fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
            קריאות שירות אחרונות
            </Typography>
            <IconButton>
                <ArrowBackIosNewIcon sx={{color:themeColors.title}}/>
            </IconButton>
        </Box>
    );
};

export default LastOrders;