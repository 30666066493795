import React from 'react';
import Layout from '../components/Layout';
import DocumentsMenu from '../components/DocumentsMenu';

const DocumentsMenuPage = () => {
    return (
        <Layout>
            <DocumentsMenu.Default/>
        </Layout>
    );
};

export default DocumentsMenuPage;