// Global
import React, {
  FC,
  createContext,
  useState,
  useContext,
  ReactNode,
} from 'react'
import Modals from '../components/Modals'

// Defines
interface ModalContextType {

  setPdfViwer: (bool: boolean) => void
  handlePdfViwer: (value: string) => void
  pdfLinkOrBase64: string
}
const ModalContext = createContext<ModalContextType | null>(null)

// React hook
const useModals = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('Can not run without "ModalContext Provider"')
  }
  return context
}

interface ModalsProviderProps {
  children: ReactNode
}
const ModalsProvider: FC<ModalsProviderProps> = ({ children }) => {
  const [pdfLinkOrBase64, setPdfLinkOrBase64] = useState('')
  const [pdfViwer, setPdfViwer] = useState(false)

  const handlePdfViwer = (value: string) => {
    setPdfLinkOrBase64(value)
    setPdfViwer(true)
  }


  const value = {
    setPdfViwer,
    handlePdfViwer,
    pdfLinkOrBase64,
  }

  return (
    <ModalContext.Provider value={value}>
      <Modals.PdfViwer
        active={pdfViwer}
        setActive={setPdfViwer}
        pdfLinkOrBase64={pdfLinkOrBase64}
      />
      {children}
    </ModalContext.Provider>
  )
}

export { useModals, ModalsProvider }
