import { ApiInterceptor } from "./api.interceptor";

export const CatalogService = {
    async getCatalog(userExtId: string, areaCode: string): Promise<ICatalog[]>{
        const response = await ApiInterceptor<ICatalog[]>(`/client/products/${userExtId}/${areaCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

}