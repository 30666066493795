import { ApiInterceptor } from "./api.interceptor";

export const FaqService = {
    async getFaq(): Promise<IFaq[]>{
        const response = await ApiInterceptor<IFaq[]>(`/client/faq`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response
    },

}