import React from 'react';
import { useArea } from '../../store/area.store';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useDataArea from '../../hooks/useDataArea';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';

const AreaChoosed = () => {
    const { choosedArea, setChoosedArea } = useArea();
    const { data } = useDataArea()
    const navigate = useNavigate()
    
    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value as string;
        const selectedArea = data!.find(item => item.code === selectedValue) || null;
        setChoosedArea(selectedArea);
        navigate(URLS.HOME.LINK)
    };

    return (
        <Box>
            <Select
                value={choosedArea?.code || ''}
                sx={{ height: '40px', minWidth: '150px' }}
                onChange={handleChange}
            >
                {data?.map((item, index) => (
                    <MenuItem value={item.code} key={index}>
                        {item.title}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

export default AreaChoosed;
