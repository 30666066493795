import { Box, Container } from '@mui/material';
import React from 'react';
import Auth from '../components/Auth';

const ResetPasswordStepThree = () => {
    return (
        <Container
                sx={{ 
                    height: '100vh', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'space-between', 
                    py: '50px', 
                    px: '20px' 
                }}
            >
                <Auth.AuthHeader/>
                <Box sx={{ flexGrow: 1, paddingTop:'100px' }}> 
                    <Auth.ResetPasswordStepThreeForm/>
                </Box>
        </Container>
    );
};

export default ResetPasswordStepThree;