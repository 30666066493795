import React, {FC, useState} from 'react';
import { Box, Card, IconButton, Typography } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import PdfHandler from '../../utils/PdfHandler';
interface CardProps {
    item: IDocument
}
const CardDoc:FC<CardProps> = ({item}) => {
    const navigate = useNavigate()
    const [openPdf, setOpenPdf] = useState(false)
    return (
        <>
        <Card sx={{display:'flex', justifyContent:'space-between', margin:'10px', padding:'10px 15px'}}>
            <Box>
                <Typography color='#154D72' fontSize={'15px'} lineHeight={'21px'} fontWeight={700}>
                {item.documentNumber}
                </Typography>
                <Typography fontSize={'13px'} fontWeight={400} lineHeight={'19.5px'} color='#436F7C'>
                 {moment(item?.deliveryDate).format('DD.MM.YYYY')}
                </Typography>
            </Box>
            <Box>
                <IconButton onClick={() => navigate(`${URLS.DOCUMENTS_ITEM.LINK}/${item.documentNumber}`)}>
                    <RemoveRedEyeOutlinedIcon color='primary'/>
                </IconButton>
                {item?.pdf &&
                    <IconButton onClick={() => setOpenPdf(!openPdf)}>
                        <FileDownloadOutlinedIcon color='primary'/>
                    </IconButton>
                }
      
            </Box>
        </Card>
        <PdfHandler base64={item?.pdf} active={openPdf} setActive={setOpenPdf}/>
        </>
    );
};

export default CardDoc;