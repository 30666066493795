import { Box, Container } from '@mui/material';
import React from 'react';
import Auth from '../components/Auth';
import MyStepper from '../utils/MyStepper';
import AuthLayout from '../components/Layout/AuthLayout';

const Login = () => {
    return (
        <AuthLayout>
            <Auth.AuthHeader />
            <Box sx={{ flexGrow: 1, paddingTop:'100px' }}> 
                <Auth.LoginForm/>
            </Box>
        </AuthLayout>
    );
};

export default Login;