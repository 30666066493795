import React from 'react';
import Layout from '../components/Layout';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import DocumentItems from '../components/DocumentItems';

const DocumentItemsPage = () => {
    return (
        <Layout>
            <DocumentItems.Info/>
            <DocumentItems.List/>
            <DocumentItems.Options/>
        </Layout>
    );
};

export default DocumentItemsPage;