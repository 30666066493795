import { Card, Skeleton, Box, Button } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const CatalogCardSkeleton = () => {
    return (
        <Card sx={{ 
            display: 'flex', 
            padding: '10px 15px', 
            borderRadius: '15px', 
            mt: '8px',
            gap: '10px', 
        }}>
            <Skeleton
                variant="rectangular"
                sx={{
                    borderRadius: '15px',
                    width: '100px',
                    height: '100px',
                    flexShrink: 0 
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Skeleton variant="text" sx={{ width: '100%', height:'44px' }} />
            </Box>
            <Button 
                variant="contained" 
                sx={{
                    width: '35px',
                    height: '35px',
                    mt:'10px',
                    minWidth: '35px', 
                    padding: '10px',
                    borderRadius:'10px',
                    boxShadow: '0px 3px 10px 0px rgba(0, 125, 131, 0.30), 0px 3px 10px 0px color(display-p3 0.0928 0.4813 0.5072 / 0.30)'  
                }} 
            >
                <AddIcon />
            </Button>
        </Card>
    );
};

export default CatalogCardSkeleton;
