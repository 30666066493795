import React, { FC } from 'react';
import { Box, Modal } from '@mui/material';

interface PdfHandlerProps {
    base64: string;
    active: boolean;
    setActive: (active: boolean) => void;
}

const PdfHandler: FC<PdfHandlerProps> = ({ base64, active, setActive }) => {

    const handleClose = () => {
        setActive(false);
    };

    return (
        <Modal
            open={active}
            onClose={handleClose}
            disableAutoFocus
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '80%',
                    height: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0, // Remove padding to ensure the iframe takes full space
                    position: 'relative',
                }}
            >
                <iframe
                    title="PDF Viewer"
                    src={`data:application/pdf;base64,${base64}`}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </Box>
        </Modal>
    );
};

export default PdfHandler;
