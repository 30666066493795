import LastOrders from "../../utils/LastDocs/LastOrders";
import LastServices from "../../utils/LastDocs/LastServices";
import Slider from "./Slider";
import LastNews from "./LastNews";
import Card from "./Card";
export default {
    LastNews,
    Slider,
    LastOrders,
    LastServices,
    Card
}