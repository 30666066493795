import { Box, Chip, Paper, Rating, TextField, Typography } from '@mui/material';
import React from 'react';
import useDataQuestion from '../../hooks/useDataQuestion';
import { QuestionEnum } from '../../types/questionEnum';

const IlustrateTwo = () => {

    const [value, setValue] = React.useState<number | null>(2);
    const [selectedChips, setSelectedChips] = React.useState<string[]>([]); 
    const { data } = useDataQuestion(QuestionEnum.ORDER)

    const handleChipClick = (chipValue: string) => {
        setSelectedChips((prevSelected) =>
            prevSelected.includes(chipValue)
                ? prevSelected.filter((item) => item !== chipValue) 
                : [...prevSelected, chipValue] 
        );
    };

    const handleDelete = (chipValue: string) => {
        setSelectedChips((prevSelected) =>
            prevSelected.filter((item) => item !== chipValue)
        );
    };

    return (
            <Box>
                <Paper sx={{ bgcolor: '#FFFFFF', textAlign: 'center', alignItems: 'center', padding: 2, boxShadow: "0px 11px 24px 0px #0E32211A", borderRadius:'15px'}}>
                    <Typography color="primary" fontWeight={700} fontSize={15}>
                        דרגו את החוויה שלכם
                    </Typography>
                    <Rating
                        size="large"
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    />
                    <Typography color="primary">
                        אנא עזור לנו להשתפר,<br />
                        נשמח לשמוע ממה הייתם פחות מרוצים:
                    </Typography>
                    {data?.map((chip, index) => (
                        <Chip
                            key={index}
                            label={chip.title}
                            onClick={() => handleChipClick(chip.title)}
                            variant={selectedChips.includes(chip.title) ? "filled" : "outlined"}
                            color={selectedChips.includes(chip.title) ? "primary" : "default"}
                        />
                    ))}
                    <TextField 
                        rows={3}
                        fullWidth
                        multiline
                        variant='outlined'
                        sx={{
                            bgcolor:'white',
                            borderRadius:'14px'
                        }}
                    />
                </Paper>
      
                <Box sx={{display:'flex', justifyContent:'center'}}>
                    <svg width="87" height="18" viewBox="0 0 87 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                            d="M-11.4521 -45.5996H68.4396C78.6383 -45.5996 86.8938 -37.3495 86.8938 -27.1874V-18.7495C86.8938 -8.57399 78.6249 -0.337288 68.4396 -0.337288H36.7808V15.9105C36.7808 17.3667 34.9937 18.0659 34.0056 16.9963L18.005 -0.323917H-11.4521C-21.6374 -0.323917 -29.9062 -8.57399 -29.9062 -18.7495V-27.1874C-29.9062 -37.3495 -21.6374 -45.5996 -11.4521 -45.5996Z" 
                            fill="white" 
                            style={{ fill: "white", fillOpacity: 1 }}
                        />
                    </svg>
                </Box>
                <Box sx={{display:'flex', justifyContent:'center'}}> 
                    <img src={`${process.env.REACT_APP_MEDIA}/one.png`} width={'204px'} height={'148px'} style={{marginLeft:'180px'}}/>
                </Box>
            </Box>
    );
};

export default IlustrateTwo;