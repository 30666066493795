import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    IconButton,
    InputAdornment
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import URLS from '../../enums/route';
import { useAuth } from '../../store/auth.store';
import { themeColors } from '../../styles';

const ResetPasswordStepThreeForm = () => {
    const [error, setError] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { restorePasswordStepThree, clearAuth } = useAuth();
    
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm<IResetPasswordStepThree>({
        mode: 'onBlur',
        defaultValues: {
            password: '',
            secondPassword: ''
        }
    });

    const onSubmit = async (data: IResetPasswordStepThree) => {
        setError('')
        try {
            const response = await restorePasswordStepThree(data.password);
            if (response.status === 'success') {
                navigate(URLS.AuthLogin.LINK);
                clearAuth()
            } else {
                setError(response.message);
            }
        } catch (e) {
            console.log('[ERROR]', e);
            setError('שגיאה');
        } 
    };

    const validatePassword = (value: string) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

        if (!hasUpperCase) return 'הסיסמה חייבת להכיל לפחות אות אחת גדולה';
        if (!hasLowerCase) return 'הסיסמה חייבת להכיל לפחות אות אחת קטנה';
        if (!hasNumber) return 'הסיסמה חייבת להכיל לפחות מספר אחד';
        if (!hasSpecialChar) return 'הסיסמה חייבת להכיל לפחות תו מיוחד אחד';
        return true;
    };

    return (
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h6" fontWeight={700} fontSize={20} lineHeight={'28px'} color={themeColors.title}>
                        קביעת סיסמה חדשה
                    </Typography>
                    <Typography>
                        השתמשו בלפחות 8 תווים, כולל אותיות A-Z, מספרים 0-9, ורצוי להוסיף גם תווים מיוחדים %^#!.
                    </Typography>
                    <Box sx={{ margin: '30px 0' }}>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: 'סיסמה נדרשת',
                                    minLength: {
                                        value: 8,
                                        message: 'הסיסמה חייבת להיות באורך של לפחות 8 תווים',
                                    },
                                    validate: validatePassword
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        placeholder="סיסמא"
                                        type={showPassword ? 'text' : 'password'}
                                        error={!!errors.password}
                                        helperText={errors.password?.message}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOffOutlinedIcon color="primary" />
                                                        ) : (
                                                            <RemoveRedEyeOutlinedIcon color="primary" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="secondPassword"
                                control={control}
                                rules={{
                                    required: 'נא לאשר את הסיסמה',
                                    validate: value => value === getValues('password') || 'הסיסמאות אינן תואמות'
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        placeholder="אותה הסיסמא שוב"
                                        type={showPassword ? 'text' : 'password'}
                                        error={!!errors.secondPassword}
                                        helperText={errors.secondPassword?.message}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOffOutlinedIcon color="primary" />
                                                        ) : (
                                                            <RemoveRedEyeOutlinedIcon color="primary" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    {error&& (
                        <Typography color="error" sx={{ textAlign: 'center', marginBottom: 2 }}>
                            {error}
                        </Typography>
                    )}
                </Box>
                <Box>
                    <Button variant='contained' fullWidth type='submit'>
                        {'שלח'}
                    </Button>
                </Box>
            </form>
    );
};

export default ResetPasswordStepThreeForm;
