import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { PermissionService } from '../services/permissions.service'
import { QuestionService } from '../services/question.service'
import { QuestionEnum } from '../types/questionEnum'

const fetchData = async (
  type: QuestionEnum,
): Promise<IQuestion[]> => {
  return await QuestionService.getQuestions(type)
}

const useDataQuestion = (type: QuestionEnum) => {

  const { data, error, isLoading, mutate } = useSWR<IQuestion[]>(
    `/client/questions/${type}`,
    () => fetchData(type)
  )

  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataQuestion

