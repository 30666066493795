import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useCart } from '../../store/cart.store';

const ClearCart = () => {
    const {clearCart} = useCart()
    return (
        <Box>
            <Typography color='#436F7C' fontSize={'15px'} fontWeight={700} lineHeight={'22.5px'}>
            שימו לב!
            </Typography>
            <Typography color='#546D79'>
            בלחיצה על <b style={{color:'#436F7C', fontSize:"15px", fontWeight:700}}>׳איפוס׳</b>, ההזמנה הנוכחית שלכם תימחק וכל המוצרים המסומנים יחזרו למצב ברירת המחדל.
            </Typography>
            <Button 
                variant='outlined' 
                color='info'
                fullWidth
                endIcon={
                    <img src={`${process.env.REACT_APP_MEDIA}/trshIcon.svg`} />
                }
                onClick={() => clearCart()}
                sx={{
                    mt:'30px',
                }}
            >
            איפוס הזמנה נוכחית
            </Button>
            <Button
                sx={{
                    mt:'20px'
                }}
                fullWidth
            >
            חזרה להזמנה
            </Button>
        </Box>
    );
};

export default ClearCart;