import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDataDocumentItems from '../../hooks/useDataDocumentItems';
import { OrderStatus } from '../../enums/orderStatus';
import { DocumentService } from '../../services/document.service';
import ModalWrapper from '../Modals/ModalWrapper';
import { useNotify } from '../../provider/NotifyProvider';
import { useAuth } from '../../store/auth.store';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { useDocuments } from '../../store/documents.store';
import { useCart } from '../../store/cart.store';

const Options = () => {
    const {id} = useParams()
    const {data,mutate,isLoading} = useDataDocumentItems(id!)
    const isCanEdit = data?.status === OrderStatus.Online 
    const [openModal, setOpenModal] = useState(false)
    const {openEdit,setOpenEdit, setChoosedDocument, setLoading, loading} = useDocuments()
    const [deliveryDate, setDeliveryDate] = useState(new Date())
    const [text, setText] = useState('')
    const {onErrorNotify, onSuccessNotify} = useNotify()
    const { user } = useAuth()
    const [comment, setComment] = useState('');
    const {clearCart,setCart, cart} = useCart()

    const handleDelete = () => {
        if(!user?.permission?.permissionDetaileds[0]?.isOrderEditable) {
            onErrorNotify('אין הרשאה לערוך הזמנה או לבטל הזמנה') 
            return 
        }
        try {
            setLoading(true)
            DocumentService.updateOrder(id!,true,data?.deliveryDate!,text).then((e) => {
                if(e.status === 'success'){
                    onSuccessNotify(e.message)
                } else {
                    onErrorNotify(e.message)
                }
                mutate().then(() => {
                    setLoading(false)
                })
            })

        } catch(e) {

        } finally {
            setOpenModal(false)
        }
    }

    const handleEdit = () => {
        if(!user?.permission?.permissionDetaileds[0]?.isOrderEditable) {
            onErrorNotify('אין הרשאה לערוך הזמנה או לבטל הזמנה') 
            return 
        }
        closeEdit()
        try {
            setLoading(true)

            DocumentService.updateOrder(
                id!,
                false,
                moment(deliveryDate).format('YYYY-MM-DD'),
                text + ' ' + comment,
                cart
            ).then((e) => {
                if(e.status === 'success'){
                    onSuccessNotify(e.message)
                } else {
                    onErrorNotify(e.message)
                }
                mutate().then(() => {
                    setLoading(false)
                })

            })

        } catch(e) {
            console.log('[ERROR]',e)
        } finally {
            setOpenModal(false)
            closeEdit()
        }
    }

    const restoreCart = () => {
        const newCart: ICart[] = data?.items?.map((item) => {
            return {
              product: item.product, 
              quantity: item.quantity, 
              sku: item.sku         
            };
        }) || [];
        setCart(newCart)
        onSuccessNotify('מוצרים התווספו לסל')
    }

    const handleOpenEdit = () => {
        clearCart()
        if(data){
            setChoosedDocument(data)
        }
        setOpenEdit(!openEdit)
        const newCart: ICart[] = data?.items?.map((item) => {
            return {
              product: item.product, 
              quantity: item.quantity, 
              sku: item.sku         
            };
        }) || [];
        setCart(newCart)

    }

    const closeEdit = () => {
        setOpenEdit(false)
        setChoosedDocument(null)
        clearCart()
    }

    useEffect(() => {
        if(data?.deliveryDate){
            setDeliveryDate(new Date(data?.deliveryDate))
        }
    },[data])

    return (
        <Box>
            <Box>
                <Typography>
                {data?.comment}
                </Typography>
            </Box>
            {!isCanEdit ?
                <>
                {!isLoading &&
                <Button 
                    variant='contained' 
                    fullWidth
                    onClick={restoreCart}
                    endIcon={
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                            d="M2 10C2 10 4.00498 7.26822 5.63384 5.63824C7.26269 4.00827 9.5136 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.89691 21 4.43511 18.2543 3.35177 14.5M2 10V4M2 10H8"
                            stroke="#F5F7FA"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                        </svg>
                    }
                >
                    שחזור הזמנה
                </Button>
                }

                </>
            :
                <>
                {(!openEdit && !loading && !isLoading) &&
                    <Box sx={{display:'flex', gap:'10px'}}>
                        <Button 
                            variant='outlined' 
                            fullWidth
                            color='info'
                            onClick={() => setOpenModal(true)}
                            endIcon={
                                <svg
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M6.00014 16H19.1359C20.1487 16 20.6551 16 21.0582 15.8112C21.4134 15.6448 21.7118 15.3777 21.9163 15.0432C22.1485 14.6633 22.2044 14.16 22.3163 13.1534L22.9013 7.88835C22.9355 7.58088 22.9525 7.42715 22.9031 7.30816C22.8597 7.20366 22.7821 7.11697 22.683 7.06228C22.5702 7 22.4155 7 22.1062 7H5.50014M3 4H4.24844C4.51306 4 4.64537 4 4.74889 4.05032C4.84002 4.09463 4.91554 4.16557 4.96544 4.25376C5.02212 4.35394 5.03037 4.48599 5.04688 4.7501L5.95312 19.2499C5.96963 19.514 5.97788 19.6461 6.03456 19.7462C6.08446 19.8344 6.15998 19.9054 6.25111 19.9497C6.35463 20 6.48694 20 6.75156 20H20M8.5 23.5H8.51M17.5 23.5H17.51M9 23.5C9 23.7761 8.77614 24 8.5 24C8.22386 24 8 23.7761 8 23.5C8 23.2239 8.22386 23 8.5 23C8.77614 23 9 23.2239 9 23.5ZM18 23.5C18 23.7761 17.7761 24 17.5 24C17.2239 24 17 23.7761 17 23.5C17 23.2239 17.2239 23 17.5 23C17.7761 23 18 23.2239 18 23.5Z"
                                    stroke="#FF32BB"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                    stroke: "#FF32BB",
                                    strokeOpacity: 1
                                    }}
                                />
                                <path
                                    d="M3 21L21.5 3"
                                    stroke="white"
                                    strokeWidth="6"
                                    strokeLinecap="round"
                                    style={{
                                    stroke: "white",
                                    strokeOpacity: 1
                                    }}
                                />
                                <path
                                    d="M2.5 21L21 3"
                                    stroke="#FF32BB"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    style={{
                                    stroke: "#FF32BB",
                                    strokeOpacity: 1
                                    }}
                                />
                                </svg>
                            }
                        >
                        בטל הזמנה
                        </Button>
                        <Button 
                            variant='contained' 
                            fullWidth
                            onClick={() => handleOpenEdit()}
                            endIcon={
                                <img src={`${process.env.REACT_APP_MEDIA}/edit.svg`} width={'24px'} height={'24px'}/>
                            }
                        >
                        ערוך הזמנה
                        </Button>
                    </Box>
                }
   
                </>
            }
            {openEdit &&
                <Box>
                    <Box sx={{margin:'15px 0'}}>
                        <Typography color='#154D72' fontSize={'15px'} fontWeight={700}>
                        בחרו תאריך למשלוח
                        </Typography>
                        <Typography color='#154D72' fontSize={'15px'} fontWeight={400}>
                        בחרו תאריך מתוך התאריכים הזמינים
                        </Typography>
                    </Box>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker  value={moment(deliveryDate)} onChange={(e) => setDeliveryDate(new Date(e?.toString()!))}/>
                    </DemoContainer>
                    <TextField
                       multiline
                       variant="outlined"
                       placeholder='הוסיפו הערה...'
                       fullWidth
                       sx={{
                        mt:'20px'
                       }}
                       value={comment}
                       onChange={(e) => setComment(e.target.value)}
                   />
                    <Box sx={{display:'flex', gap:'10px', mt:'20px'}}>
                        <Button
                            fullWidth
                            variant='contained'
                            onClick={() => handleEdit()}
                        >
                        עדכן הזמנה
                        </Button>
                        <Button
                            fullWidth
                            variant='outlined'
                            color='info'
                            onClick={() => closeEdit()}
                        >
                        בטל עריכה 
                        </Button>
                    </Box>
                </Box>
            }

            <ModalWrapper 
                active={openModal} 
                setActive={setOpenModal}
                component={
                    <Typography color='#154D72' fontWeight={700} lineHeight={'28px'} fontSize={'20px'}>
                        לבטל את ההזמנה?
                    </Typography>
                }
            >
                <Box>
                    <Typography color='#436F7C' fontWeight={700} lineHeight={'22.5px'} fontSize={'15px'}>
                    שימו לב! 
                    </Typography>
                    <Typography fontSize={'15px'} fontWeight={400}color='#436F7C'>
                    בלחיצה על ״בטל הזמנה״ המידע שהוזן בהזמנה זו ימחק.
                    </Typography>
                    <Typography sx={{mt:'30px'}} color='#436F7C' fontWeight={700} lineHeight={'22.5px'} fontSize={'15px'}>
                    אנא ציינו את סיבת הביטול
                    </Typography>
                    <Typography fontSize={'15px'} fontWeight={400}color='#436F7C'>
                    הזינו לפחות 15 תווים
                    </Typography>
                    <TextField
                        sx={{
                            mt:'10px'
                        }}
                        placeholder='סיבת ביטול הזמנה'
                        fullWidth
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                    <Box>
                        <Button 
                            variant='outlined' 
                            fullWidth
                            color='info'
                            sx={{
                                mt:'20px'
                            }}
                            endIcon={
                                <svg
                                width="25"
                                height="26"
                                viewBox="0 0 25 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M6.00014 16H19.1359C20.1487 16 20.6551 16 21.0582 15.8112C21.4134 15.6448 21.7118 15.3777 21.9163 15.0432C22.1485 14.6633 22.2044 14.16 22.3163 13.1534L22.9013 7.88835C22.9355 7.58088 22.9525 7.42715 22.9031 7.30816C22.8597 7.20366 22.7821 7.11697 22.683 7.06228C22.5702 7 22.4155 7 22.1062 7H5.50014M3 4H4.24844C4.51306 4 4.64537 4 4.74889 4.05032C4.84002 4.09463 4.91554 4.16557 4.96544 4.25376C5.02212 4.35394 5.03037 4.48599 5.04688 4.7501L5.95312 19.2499C5.96963 19.514 5.97788 19.6461 6.03456 19.7462C6.08446 19.8344 6.15998 19.9054 6.25111 19.9497C6.35463 20 6.48694 20 6.75156 20H20M8.5 23.5H8.51M17.5 23.5H17.51M9 23.5C9 23.7761 8.77614 24 8.5 24C8.22386 24 8 23.7761 8 23.5C8 23.2239 8.22386 23 8.5 23C8.77614 23 9 23.2239 9 23.5ZM18 23.5C18 23.7761 17.7761 24 17.5 24C17.2239 24 17 23.7761 17 23.5C17 23.2239 17.2239 23 17.5 23C17.7761 23 18 23.2239 18 23.5Z"
                                    stroke="#FF32BB"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{
                                    stroke: "#FF32BB",
                                    strokeOpacity: 1
                                    }}
                                />
                                <path
                                    d="M3 21L21.5 3"
                                    stroke="white"
                                    strokeWidth="6"
                                    strokeLinecap="round"
                                    style={{
                                    stroke: "white",
                                    strokeOpacity: 1
                                    }}
                                />
                                <path
                                    d="M2.5 21L21 3"
                                    stroke="#FF32BB"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    style={{
                                    stroke: "#FF32BB",
                                    strokeOpacity: 1
                                    }}
                                />
                                </svg>

                            }
                            disabled={text.length < 15}
                            onClick={handleDelete}
                        >
                        ביטול הזמנה
                        </Button>
                    </Box>
                    <Box>
                        <Button 
                            sx={{
                                mt:'20px'
                            }}
                            fullWidth
                            onClick={() => setOpenModal(false)}
                        >
                        חזרה לסיכום הזמנה
                        </Button>
                    </Box>
                </Box>
            </ModalWrapper>
        </Box>
    );
};

export default Options;