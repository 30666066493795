import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { ThemeProvider, CssBaseline, createTheme, Direction } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import i18next from 'i18next';
import global_en from '../locales/en/global.json';
import global_hw from '../locales/he/global.json';
import theme from '../styles';

interface LanguageContextProps {
  direction: Direction; 
  changeLanguage: (value: string) => void;
}

const LanguageContext = createContext<LanguageContextProps | null>(null);

const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('Cannot run without "LanguageContext Provider"');
  }
  return context;
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'he',
  resources: {
    he: {
      translation: global_hw,
    },
    en: {
      translation: global_en,
    },
  },
});

interface LanguageAndStyleProviderProps {
  children: ReactNode;
}

const LanguageAndStyleProvider: FC<LanguageAndStyleProviderProps> = ({ children }) => {
  const [direction, setDirection] = useState<Direction>('rtl'); 
  
  const [cache, setCache] = useState(createCache({
    key: 'mui',
    stylisPlugins: direction === 'rtl' ? [rtlPlugin, prefixer] : [],
  }));

  useEffect(() => {
    const newCache = createCache({
      key: direction === 'rtl' ? 'mui-rtl-cache' : 'mui-ltr-cache',
      stylisPlugins: direction === 'rtl' ? [rtlPlugin, prefixer] : [],
    });
    setCache(newCache);
  }, [direction]);

  const changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
    setDirection(lang === 'he' ? 'rtl' : 'ltr');
  };

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  })

  return (
    <LanguageContext.Provider value={{ changeLanguage, direction }}>
      <CacheProvider value={cacheRtl}>
        <I18nextProvider i18n={i18next}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="he">
              {children}
            </LocalizationProvider>
          </ThemeProvider>
        </I18nextProvider>
      </CacheProvider>
    </LanguageContext.Provider>
  );
};

export { LanguageAndStyleProvider, useLanguage };
