import { Box } from '@mui/material';
import React from 'react';
import Machine from '../components/Machine';
import MachineDocument from '../components/MachineDocument';
import Layout from '../components/Layout';
import SecondPageLayout from '../components/Layout/SecondPageLayout';

const ServiceDocumentPage = () => {
    return (
        <SecondPageLayout>
            <MachineDocument.ErrorDescription/>
            <MachineDocument.FilesList/>
            {/* <MachineDocument.Edit/> */}
        </SecondPageLayout>
    );
};

export default ServiceDocumentPage;