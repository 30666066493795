import React, {
    FC,
    createContext,
    useContext,
    ReactNode,
    useEffect,
  } from 'react'
  import { useLocation, useNavigate, } from 'react-router-dom'
  import { useAuth } from '../store/auth.store'
import URLS from '../enums/route'

  interface ModalContextType {
  }
  const ModalContext = createContext<ModalContextType | null>(null)
  
  const useAuthProvider = () => {
    const context = useContext(ModalContext)
    if (!context) {
      throw new Error('Can not run without "Auth Context Provider"')
    }
    return context
  }
  
  interface AuthProviderProps {
    children: ReactNode
  }
  const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const {user} = useAuth()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const isAuthrized = user?.id ?? false

    useEffect(() => {
        if(
          pathname == URLS.AuthHome.LINK || 
          pathname == URLS.AuthLogin.LINK ||
          pathname == URLS.AuthStepOne.LINK || 
          pathname == URLS.AuthStepTwo.LINK || 
          pathname == URLS.AuthStepThree.LINK || 
          pathname == URLS.RESET_PASSWORD_STEP_ONE.LINK || 
          pathname == URLS.RESET_PASSWORD_STEP_TWO.LINK || 
          pathname == URLS.RESET_PASSWORD_STEP_THREE.LINK
        ){
          if(isAuthrized) {
            navigate(URLS.HOME.LINK)
          }
        } else {
          if(!isAuthrized) {
            navigate(URLS.AuthHome.LINK)
          }
        }
   
    }, [pathname])

    const value = {

    }

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  }
  
  export { useAuthProvider, AuthProvider }
  