import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { ServicesService } from '../services/services.service'
import { useArea } from '../store/area.store'

const fetchData = async (
  userExId: string,
  area: string
): Promise<IMachine[]> => {
  return await ServicesService.getMachines(userExId,area)
}

const useDataMachines = () => {
  const { user } = useAuth()
  const { choosedArea } = useArea()
  const { data, error, isLoading, mutate } = useSWR<IMachine[]>(
    `/client/machines/${user?.extId}/${choosedArea?.code}`,
    () => fetchData(user?.extId!,choosedArea?.code!)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataMachines

