import useSWR from 'swr'
import { useAuth } from '../store/auth.store'
import { AreaService } from '../services/area.service'

const fetchData = async (
  userExId: string,
  userId: string | number
): Promise<IArea[]> => {
  return await AreaService.getArea(userExId, userId)
}

const useDataArea = () => {
  const { user } = useAuth()
  const { data, error, isLoading, mutate } = useSWR<IArea[]>(
    `/client/areas/${user?.extId}/${user?.id}`,
    () => fetchData(user?.extId!,user?.id!)
  )
  return {
    data,
    isLoading: isLoading,
    isError: error,
    mutate,
  }
}

export default useDataArea

