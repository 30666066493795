import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface CatalogState {
    favorites: IProduct[]
    setFavorites: (product: IProduct) => void
    openFavorite: boolean
    setOpenFavorite: (openFavorite: boolean) => void
    isExistFavorite: (productId: number) => boolean;
    openFilter: boolean
    setOpenFilter: (openFilter: boolean) => void
    clearFilter: () => void
    search: string,
    setSearch: (search: string) => void
    selectedCategories: string[]
    setSelectedCategoies: (selectedCategories: string[]) => void
}

export const useCatalog = create(
  persist(
    (set, get) => ({
        favorites: [],
        setFavorites: (product: IProduct) => {
            set((state) => {
                const favorites = state.favorites.some(item => item.id === product.id)
                    ? state.favorites.filter(item => item.id !== product.id) 
                    : [...state.favorites, product]; 
                return { favorites };
            });
        },
        isExistFavorite: (productId) => {
            return get().favorites.some(item => item.id === productId);
        },
        openFilter: false,
        setOpenFilter: (openFilter) => set({openFilter}),
        clearFilter: () => {
          set({openFilter:false,selectedCategories:[]})
        },
        openFavorite:false,
        setOpenFavorite: (openFavorite) => set({openFavorite}),
        search: '',
        setSearch: (search) => set({search}),
        selectedCategories: [],
        setSelectedCategoies: (selectedCategories) => set({selectedCategories}),
    }),
    {
      name: 'catalog-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<CatalogState, CatalogState>
  )
)
