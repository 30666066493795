import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import Header from './Header';
import FooterNavigation from './FooterNavigation';
import PullToRefresh from 'react-simple-pull-to-refresh';
import theme, { themeColors } from '../../styles';
import { useLocation } from 'react-router-dom';
import URLS from '../../enums/route';
import useDataMachines from '../../hooks/useDataMahines';

interface LayoutProps {
    children: React.ReactNode;
    widhoutPadding?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children,widhoutPadding = true }) => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { pathname } = useLocation()
    const {data} = useDataMachines()

    const handleRefresh = () => {
        setIsRefreshing(true);
        return new Promise((resolve) => {
            // Simulate a data fetch or any async operation
            setTimeout(() => {
                setIsRefreshing(false); // End refresh after 2 seconds
                resolve('');
            }, 2000);
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Box
                component="header"
                sx={{
                    position: 'fixed',
                    top: 0,
                    paddingTop:'50px',
                    left: 0,
                    right: 0,
                    zIndex: 1100, 
                    bgcolor:themeColors.baseDark
                }}
            >
                <Header />
                {isRefreshing && (
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', mt:'30px'}}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
            <PullToRefresh
                onRefresh={handleRefresh}
                refreshingContent={<></>} 
            >
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        marginTop: '165px', 
                        marginBottom: '104px',
                        overflowY: 'auto',
                        position: 'relative', 
                        padding:widhoutPadding ? '0 10px' : '0px',
                        paddingBottom:'40px'
                    }}
                >
    
                    {children}
                </Box>
            </PullToRefresh>
            <Box
                component="footer"
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1100, 
                }}
            >
                {pathname === URLS.SERVICE.LINK && data?.length === 0 &&
                <Box>
                    <Typography color='#79A3B4' fontSize={'13px'} fontWeight={700} textAlign={'center'}>
                    אופס!
                    </Typography>
                    <Typography sx={{mb:'16px'}} color='#79A3B4' fontSize={'13px'} fontWeight={400} textAlign={'center'}>
                    נראה שאין פה כלום כרגע.
                    </Typography>
                    <Box sx={{display:'flex', justifyContent:'center'}}>
                        <img src={`${process.env.REACT_APP_MEDIA}/noMachines.svg`} style={{alignItems:'center'}}/>
                    </Box>
                </Box>
                }

                <FooterNavigation />
            </Box>
        </Box>
    );
};

export default Layout;
