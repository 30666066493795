import React from 'react';
import { useCart } from '../../store/cart.store';
import CatalogCard from '../Catalog/CatalogCard';

const List = () => {
    const { cart } = useCart()
    return (
        <div>
            {cart?.map((item) => 
                <CatalogCard item={item.product}/>
            )}
        </div>
    );
};

export default List;