import { create } from 'zustand'
import { persist, createJSONStorage, PersistOptions } from 'zustand/middleware'

interface AvatarState {
    choosedAvatar: string,
    setChoosedAvatar: (choosedArea: string) => void
    avatarId: number | null
    setAvatarId: (avatarId: number) => void
}

export const useAvatarStore = create(
  persist(
    (set, get) => ({
        choosedAvatar: '',
        setChoosedAvatar: (choosedAvatar) => set({choosedAvatar}),
        avatarId:null,
        setAvatarId: (avatarId) => set({avatarId})
    }),
    {
      name: 'avatar-storage',
      storage: createJSONStorage(() => localStorage),
    } as PersistOptions<AvatarState, AvatarState>
  )
)
