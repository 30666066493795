import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import useDataMachineDocument from '../../hooks/useDataMachineDocument';
import { ServiceStatus } from '../../enums/serviceStatus';
import ModalWrapper from '../Modals/ModalWrapper';
import { ServicesService } from '../../services/services.service';
import { useParams } from 'react-router-dom';
import { useNotify } from '../../provider/NotifyProvider';
import { useAuth } from '../../store/auth.store';

const Edit = () => {
    const [loading, setLoading] = useState(false)
    const { onErrorNotify, onSuccessNotify} = useNotify()
    const {data} = useDataMachineDocument()
    const { user } = useAuth()
    const {id} = useParams()
    const [text,setText] = useState('')
    const isCanEdit = (data?.status === ServiceStatus.Online || data?.status === ServiceStatus.Open || data?.status === ServiceStatus.ForAssignment) 
    const [openDelete, setOpenDelete] = useState(false)
    
    const handleDelete = () => {
      if(!user?.permission?.permissionDetaileds[0]?.isServiceEditable){
        onErrorNotify('אין הרשאה לערוך קריאת שירות או לבטל קריאת שירות') 
        return 
      }
      try {
        setLoading(true)
        const response = ServicesService.updateService(id!,true,`${data?.comment} - ${text}`).then((e) => {
          e.status === 'success' ? onSuccessNotify(e.message) : onErrorNotify(e.message) 
        })
        
      } catch(e) {
        console.log('[ERROR]:',e)
      } finally {
        setLoading(false)
        setOpenDelete(false)
      }
    }

    return (
        <Box sx={{gap:'10px', display:'flex'}}>
          {isCanEdit &&
            <>
              <Button variant='outlined' color='error' fullWidth onClick={() => setOpenDelete(true)}>בטל קריאה</Button>  
              <Button variant='contained' color='primary' fullWidth>ערוך קריאה</Button>  
            </>
          }
          <ModalWrapper active={openDelete} setActive={setOpenDelete}>
            <Box>
              <Typography>
              לבטל קריאת שירות?
              </Typography>
              <Typography>
              שימו לב! 
              </Typography>
              <Typography>
              אתם עומדים לבטל את קריאת השירות, בלחיצה על ״בטל קריאה״ המידע שהוזן בקריאה הזו לא ישמר.
              </Typography>
              <Typography>
              ציינו את סיבת ביטול הקריאה
              </Typography>
              <Typography>
              כתבו לפחות 10 תווים
              </Typography>
              <TextField 
                placeholder='סיבת ביטול קריאת השירות'
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <Box>
                <Button 
                variant='contained' 
                fullWidth
                disabled={text.length < 10}
                onClick={handleDelete}
                >
                בטל קריאה
                </Button>
              </Box>
              <Box>
                <Button 
                fullWidth
                onClick={() => setOpenDelete(false)}
                >
                חזרה לסיכום הקריאה
                </Button>
              </Box>
            </Box>
          </ModalWrapper>
        </Box>
    );
};

export default Edit;