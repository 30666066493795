import { Box, Typography } from '@mui/material';
import React from 'react';
import useDataNotifications from '../../hooks/useDataNotifications';
import Card from './Card';
import CardNotification from './CardNotification';

const List = () => {
    const { data, isLoading } = useDataNotifications();

    return (
        <Box>
            <Typography 
                variant='h4' 
                fontWeight={700} 
                lineHeight={'28px'} 
                fontSize={'20px'} 
                color='#154D72' 
                sx={{ padding: '5px 20px' }}
            >
                התראות
            </Typography>
            
            {isLoading ? (
                [...Array(5)].map((_, index) => (
                    <CardNotification key={index} />
                ))
            ) : (
                data?.map((item) => (
                    <Card key={item.id} item={item} />
                ))
            )}
        </Box>
    );
};

export default List;
